.login-page {
  min-height: 100vh;
  background: rgb(51, 1, 72);
  background-image: linear-gradient(
      90deg,
      rgba(51, 1, 72, 0.8) 10%,
      rgba(55, 10, 146, 0.5) 100%,
      rgba(34, 34, 34, 0.4) 100%
    ),
    url("https://images.unsplash.com/photo-1432821596592-e2c18b78144f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bG9naW4lMjBwYWdlJTIwYmFja2dyb3VuZCUyMGltYWdlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bd-pass-icon {
  position: absolute;
  top: 10px;
  right: 11px;
}
.bd-pass {
  position: relative;
}
