#vendorspay {
  .pop-icon {
    display: none;
  }

  .t-col-1 {
    width: 4% !important;
    min-width: fit-content !important;

  }

  .t-col-2 {
    width: 6.5% !important;
    min-width: fit-content !important;

  }

  .t-col-3 {
    width: 23% !important;
  }

  .t-col-4 {
    width: 23% !important;
  }

  .t-col-5 {
    width: 10% !important;
  }

  .t-col-6 {
    width: 10% !important;

  }

  .t-col-7 {
    width: 10% !important;
    min-width: fit-content !important;

  }

  .t-col-8 {
    width: 7% !important;
    min-width: fit-content !important;

  }

  .t-col-9 {
    width: 10% !important;
  }

  .t-col-10 {
    width: 10% !important;
  }

  .fix-header .t-col-1 {
    width: 4% !important;
    min-width: fit-content !important;

  }

  .fix-header .t-col-2 {
    width: 5.5% !important;
    min-width: fit-content !important;
  }

  .fix-header .t-col-3 {
    width: 23% !important;
    min-width: fit-content !important;
  }

  .fix-header .t-col-4 {
    width: 23% !important;
    min-width: fit-content !important;

  }

  .fix-header .t-col-5 {
    width: 10% !important;
    min-width: fit-content !important;

  }

  .fix-header .t-col-6 {
    width: 10% !important;

  }

  .fix-header .t-col-7 {
    width: 9.7% !important;
    min-width: fit-content !important;

  }

  .fix-header .t-col-8 {
    width: 7% !important;
    min-width: fit-content !important;

  }

  .fix-header .t-col-9 {
    width: 7% !important;

  }

  .fix-header .t-col-10 {
    width: 7% !important;

  }
}


@media screen and (min-width: 1900px) {
  #vendorspay .fix-header {
    width: 92.2%;
    left: 3.8%;
  }
}

@media screen and (max-width: 1600px) {
  #vendorspay {
    .pop-icon {
      display: none;
    }
  
    .t-col-1 {
      width: 6% !important;
      min-width: fit-content !important;
    }
  
    .t-col-2 {
      width: 8.5% !important;
      min-width: fit-content !important;
      padding-left: 3px !important;
      padding-right: 3px !important;
    }
  
    .t-col-3 {
      width: 23% !important;
    }
  
    .t-col-4 {
      width: 23% !important;
    }
  
    .t-col-5 {
      width: 10% !important;
    }
  
    .t-col-6 {
      width: 10% !important;
  
    }
  
    .t-col-7 {
      width: 10% !important;
      min-width: fit-content !important;
  
    }
  
    .t-col-8 {
      width: 7% !important;
      min-width: fit-content !important;
  
    }
  
    .t-col-9 {
      width: 10% !important;
    }
  
    .t-col-10 {
      width: 10% !important;
    }
  
    .fix-header .t-col-1 {
      width: 4% !important;
      min-width: fit-content !important;
  
    }
  
    .fix-header .t-col-2 {
      width: 5.5% !important;
      min-width: fit-content !important;
    }
  
    .fix-header .t-col-3 {
      width: 21% !important;
      min-width: fit-content !important;
    }
  
    .fix-header .t-col-4 {
      width: 21% !important;
      min-width: fit-content !important;
  
    }
  
    .fix-header .t-col-5 {
      width: 10% !important;
      min-width: fit-content !important;
  
    }
  
    .fix-header .t-col-6 {
      width: 9% !important;
      padding-left: 3px !important;
      padding-right: 3px !important;
    }
  
    .fix-header .t-col-7 {
      width: 9.2% !important;
      min-width: fit-content !important;
  
    }
  
    .fix-header .t-col-8 {
      width: 6.5% !important;
      min-width: fit-content !important;
  
    }
  
    .fix-header .t-col-9 {
      width: 9.8% !important;
  
    }
  
    .fix-header .t-col-10 {
      width: 9.8% !important;
  
    }
  }
}