/* #viewBox{
    position: relative;
}

#viewBox .topNav{

} */

.topic-search-input {
  position: relative;
}
.topic-search-icon {
  position: absolute;
  top: 13px;
  left: 25px;
  width: 25px;
  /* transform: translate(50%, 50%); */
}
#knowledgeBase .nav-pills .show > .nav-link {
  background-color: transparent !important;
  color: #495057 !important;
}
#knowledgeBase .nav-pills .show > .active.nav-link {
  /* background-color: #5c0385d6 !important;
  color: white !important; */
  border: 2px solid #5c0385d6;
}
