@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

/* fixed header styling */
.fix-contract-header .t-col-1 {
  width: 4%;
}

.fix-contract-header .t-col-2 {
  width: 8.5%;
}
.fix-contract-header .t-col-3 {
  width: 20%;
}

.fix-contract-header .t-col-4 {
  width: 13.7%;
}

.fix-contract-header .t-col-5 {
  width: 9%;
}

.fix-contract-header .t-col-6 {
  width: 8.5%;
}

.fix-contract-header .t-col-7 {
  width: 9%;
}

.fix-contract-header .t-col-8 {
  width: 8.5%;
}

.fix-contract-header .t-col-9 {
  width: 8.8%;
}

.fix-contract-header .t-col-10 {
  width: 10%;
}

.bg-dynamic-field {
  background-color: #f3f6f9;
  display: block;
  border: none;
  width: 100%;
}
.copyBtn {
  border-radius: 2px !important;
}

.doc-header {
  border-bottom: 1px solid #ededed;
}
.paragraph {
  text-align: justify;
}

.fixed-right-section {
  position: fixed !important;
  top: 24%;
  right: 3.3%;
  width: 22.5%;
}

.copy-modal-line {
  position: absolute !important;
  width: 0.065% !important;
  height: 96%;
  background-color: rgba(128, 128, 128, 0.253);
  top: 0;
  left: 50%;
}
.sign-box {
  width: 85%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
  opacity: 0.4;
  margin-bottom: 10px;
}

.text-center.mt-70 {
  position: absolute;
  top: 58%;
  left: 58%;
}
.download-contract {
  max-width: 800px !important;
}
.width-0 {
  width: 0px !important;
  height: 0px !important;
}
/* .tramsition-collapse{
  transition: 0.4s all;
} */
.padding-0 {
  padding: 0px !important;
}
.tox .tox-notification--in {
  display: none !important;
}
.signatureHeight {
  height: 15rem !important;
}
@media screen and (max-width: 600px) {
  .fixed-right-section {
    position: unset !important;
    top: unset;
    right: unset;
    width: unset;
  }
  .signatureHeight {
    height: 12rem !important;
  }
}
