.Audit-fix-header .t-head-1 {
  width: 6.1%;
  padding-left: 0;
  padding-right: 0;
}
.Audit-fix-header .t-head-2 {
  width: 11.7%;
}
.Audit-fix-header .t-head-3 {
  width: 11.7%;
}
.Audit-fix-header .t-head-4 {
  width: 11.2%;
}
.Audit-fix-header .t-head-5 {
  width: 9.4%;
}
/* .table-roles-audit .t-head-1 {
  width: 8%;
} */
.t-head-1 {
  width: 12%;
}
.t-head-2 {
  width: 23%;
}
.t-head-3 {
  width: 25%;
}
.t-head-4 {
  width: 20%;
}
.t-head-5 {
  width: 20%;
}

#Audit-roles .Audit-fix-header {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 67.8%;
  left: 27.8%;
}
.auditRole {
  position: fixed;
  width: 24%;
  top: 31%;
  transform: translateY(-20%);
  transition: all 5s ease;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.075) !important;
  padding: 0 1rem;
}
.filter-btn{
  position: relative;
}
#formPopup .popup-form{
  background-color: white;
  width: 50%;
  position: absolute;
  top: 65px;
  right: 30%;
  box-shadow: 2px 2px 50px #8080807e;
  z-index: 99;
}
@media(min-width : 1540px){
  #formPopup .popup-form{
    right: 19%;
  }
}
.dateIntput{
  padding: 2px;
  width: 130px;
  margin-left: 10px;
  border-radius: 12px !important;
}


.sidebar-menu{
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  

}
.fix-notification-header {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  /* top: 19.2%; */
  top: 120px;
  width: 91.2%;
  left: 4.4%;
}

.notification_id {
  width: 1% !important;
  text-align: center !important;
}

.notification_date {
  width: 92% !important;
  text-align: center !important;
}

.notification_module_name {
  width: 7% !important;
}

.notification_title {
  width: 60% !important;
}

.notification_action {
  width: 3% !important;
  text-align: center;
}

.fix-notification-header .notification_id {
  width: 1% !important;
}

.fix-notification-header .notification_date {
  width: 4% !important;
}

.fix-notification-header .notification_module_name {
  width: 7% !important;
}

.fix-notification-header .notification_title {
  width: 40% !important;
}

.fix-notification-header .notification_action {
  width: 3% !important;
}

.module-icon i{
  font-size: 20px;
}