/* fixed header styling */
.fix-proposal-header .t-col-1 {
    width: 4%;
}

.fix-proposal-header .t-col-2 {
    width: 10%;
}
.fix-proposal-header .t-col-3 {
    width: 14%;
}

.fix-proposal-header .t-col-4 {
    width: 15%;
}

.fix-proposal-header .t-col-5 {
    width: 8%;
}

.fix-proposal-header .t-col-6 {
    width: 10%;
}

.fix-proposal-header .t-col-7 {
    width: 10%;
}

.fix-proposal-header .t-col-8 {
    width: 10%;
}

.fix-proposal-header .t-col-9 {
    width: 8%;
}

.fix-proposal-header .t-col-10 {
    width: 11%;
}

.proposal-status{
    background-color: #5c038517;
    width: 22%;
}
.proposal-status:hover{
    background-color: #5c038533;
}
.pdf_box{
    display: flex;
    width: 90%;
    margin: 4px auto;
    justify-content: space-between;
    padding: 20px;
}
.pdf_col{
    width: 31%;
    padding: 10px;
    background-color: antiquewhite;
    color: blueviolet;
    font-size: 16px;
}
#pdf{
    width: 100%;
    padding: 40px 30px;
}
.bg-green{
    background-color: rgba(38, 233, 38, 0.082) !important;
}
.bg-red{
    background-color: rgba(255, 0, 0, 0.089) !important;
}
.bg-blue{
    background-color: rgba(0, 0, 255, 0.075) !important;
}
.bg-gray{
    background-color: rgba(128, 128, 128, 0.192) !important;
}
.bg-green td, .bg-red td, .bg-blue td, .bg-gray td{
    border: none;
}
.button-info-proposal{
    padding: 8px;
    border-radius: 4px;
    color: white;
    line-height: 0;
}
.button-info-proposal:hover{
    color: white;
}