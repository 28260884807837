.emp-id{
    width: 5% !important;
}

.emp-code{
    width: 8% !important;
    /* width: 11.5% !important; */
}
.puch-id{
    width: 8% !important;
}
.emp-name{
    width:unset !important;
}
.department{
    width: 20% !important
}
.Designation{
    width: 20% !important;
}
.action{
    width: 8% !important;
}

/* .fixed-punch-details-header .col-t-2{
  width: 11.5% !important;

} */

/* // Punch details Section  */

@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
    padding: 0;
    margin: 0;
    background-color: #f6f6f6;
    font-family: 'Roboto', sans-serif;
}

#container {
    background-color: #fff;
}

.step-wrapper {
    padding: 20px 0;
    display: none;
}

.step-wrapper.active {
    display: block;
}

.step-indicator {
    border-collapse: separate;
    display: table;
    margin-left: 0px;
    position: relative;
    table-layout: fixed;
    text-align: center;
    vertical-align: middle;
    padding-left: 0;
    padding-top: 20px;
}

.myTableView{
  height: 350px !important;
  overflow-y: scroll;
  scrollbar-width: thin;
}
/* .fixed-table{
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
} */
.step-indicator li {
    display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;
}

.step-indicator li:after {
    background-color: #ccc;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    width: 100%;
    top: 32px; /* half of $wizard-step-width-height (64px) */
    left: 50%;
}

.step-indicator li:last-child:after {
    display: none;
}

.step-indicator li.active .step {
    border-color: #4183D7;
    color: #4183D7;
}

.step-indicator li.active .caption {
    color: #4183D7;
}

.step-indicator li.complete:after {
    background-color: #87D37C;
}

.step-indicator li.complete .step {
    border-color: #87D37C;
    color: #87D37C;
}

.step-indicator li.complete .caption {
    color: #87D37C;
}

.step {
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ccc;
    color: #ccc;
    font-size: 24px;
    height: 64px;
    line-height: 64px;
    margin: 0 auto;
    position: relative;
    width: 64px;
    z-index: 1;
}

.step:hover {
    cursor: pointer;
}

.caption {
    color: #ccc;
    padding: 11px 16px;
}


/* // Table paymenet  */
.pay-col-1{
    width: 10% !important;
}
.pay-col-2{
    width: 10% !important;
}
.pay-col-3{
    width: 10% !important;
}
.pay-col-4{
    width: 10% !important;
}
.pay-col-5{
    width: 15% !important;
}
.pay-col-6{
    width: 10% !important;
}
.pay-col-7{
    width: 10% !important;
}


/* //  */
/* Google Fonts Import Link */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap'); */
/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} */
.sidebar{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #11101d;
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close{
  width: 78px;
}
.sidebar .logo-details{
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i{
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name{
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name{
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links{
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links{
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar{
  display: none;
}
.sidebar .nav-links li{
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover{
  background: #1d1b31;
}
.sidebar .nav-links li .iocn-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .iocn-link{
  display: block
}
.sidebar .nav-links li i{
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow{
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow{
  display: none;
}
.sidebar .nav-links li a{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name{
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name{
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu{
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu{
  display: block;
}
.sidebar .nav-links li .sub-menu a{
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover{
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu{
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu{
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name{
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name{
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank{
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank{
  top: 50%;
  transform: translateY(-50%);
}

.one {
  width: 80%;
  margin-left: 10%;
  background-color: black;
  height: 400px;
}

.sidebar .profile-details{
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details{
  background: none;
}
.sidebar.close .profile-details{
  width: 78px;
}
.sidebar .profile-details .profile-content{
  display: flex;
  align-items: center;
}
.sidebar .profile-details img{
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img{
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job{
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job{
  display: none;
}
.sidebar .profile-details .job{
  font-size: 12px;
}
.home-section{
  position: relative;
  background: #E4E9F7;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
}
.sidebar.close ~ .home-section{
  left: 78px;
  width: calc(100% - 78px);
}
.home-section .home-content{
  height: 60px;
  display: flex;
  align-items: center;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text{
  color: #11101d;
  font-size: 35px;
}
.home-section .home-content .bx-menu{
  margin: 0 15px;
  cursor: pointer;
}
.home-section .home-content .text{
  font-size: 26px;
  font-weight: 600;
}
@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu{
    display: none;
  }
}

/* // Leave Management  */
.fix-leave-table-body tr:nth-child(2){
background-color: #ffe8e8 !important;
}
.fix-leave-table-body tr:nth-child(5){
background-color: #ffe8e8 !important;
}
.fixed-leave-mng-header{
  position: fixed;
background-color: #981f77 !important;
color: white;
z-index: 40;
/* top: 19.2%; */
top: 120px;
width: 91.7%;
left: 4.1%;
}

.fixed-leave-mng-header .col-t-1{
  width: 6% !important;
}
.lemp-id{
  width: 6% !important;
}
/* .fixed-leave-mng-heade .lemp-id{
  width: 6.4% !important;
} */

.fixed-leave-mng-header .col-t-2{
  width: 8% !important;
}
.lapplied_on_box{
  width: 8% !important; 
}


.fixed-leave-mng-header .col-t-3{
  /* width: 28% !important; */
  width: 15% !important;
}
.lemployee_name_box{
  width: 12% !important;
}

.fixed-leave-mng-header.col-t-4{
  width: 13% !important;
}
.lemployee_code-box{
  width: 9.5% !important;
}

.fixed-leave-mng-header .col-t-5{
  width: 7% !important;
}
.lpunch_id_box{
  width: 7% !important;
}

.fixed-leave-mng-header .col-t-6{
  width: 47% !important;
  /* width: unset !important; */
}
.lreason_box{
  width: unset !important;
}
.laction{
  width: 7% !important;

}
.fixed-leave-mng-header .col-t-7{
  width: 4.2% !important;
}
.gprioritys_col_box{
  width: 8% !important;
}

@media screen and (min-width : 1250px) and (max-width : 1400px) {
  .lemp-id{
    width: 8% !important;
  }
  .lapplied_on_box {
    width: 10% !important;
  }
  .lemployee_name_box {
    width: 15% !important;
  }
  .lemployee_code-box {
    width: 12.5% !important;
  }
  .lpunch_id_box {
    width: 9% !important;
  }
  .lreason_box {
    width: unset !important;
  }
  .laction {
    width: 9% !important;
  }

  .fixed-leave-mng-header .col-t-1{
    width: 9% !important;
  }
  .fixed-leave-mng-header .col-t-2{
    width: 10% !important;
  }
  .fixed-leave-mng-header .col-t-3{
    width: 16% !important;
  }
  .fixed-leave-mng-header .col-t-4{
    width: 12.5% !important;
  }
  .fixed-leave-mng-header .col-t-5{
    width: 9% !important;
  }
  .fixed-leave-mng-header .col-t-6{
    width: 42% !important;
  }
  .fixed-leave-mng-header .col-t-7{
    width: 4% !important;
  }
}

/* // Punch Details Screen  */
.fixed-punch-details-header{
  position: fixed;
  background-color: #981f77 !important;
  color: white;
  z-index: 40;
  /* top: 19.2%; */
  top: 120px;
  width: 91.7%;
  left: 4.1%;
}

/* // Salary Sheet  */
.fixed-salary-mng-header{
  position: fixed;
  background-color: #981f77 !important;
  color: white;
  z-index: 40;
  /* top: 19.2%; */
  top: 120px;
  width: 90.2%;
  left: 4.1%;
}

.fixed-salary-mng-header .col-t-1{
  width: 8% !important;
}
.salary-sr{
  width: 5.5% !important;
}

.fixed-salary-mng-header .col-t-2{
  width: 17% !important;
}
.salary-name{
  width: 12% !important;
}

.fixed-salary-mng-header .col-t-2{
  width: 8% !important;
}
.salary-days{
  width: 7% !important;
}

.salary-asl{
  width: 5% !important;
}

.salary-usl{
  width: 5% !important;
}

.salary-ael{
  width: 5% !important;
}

.salary-uel{
  width: 5% !important;
}

.salary-acl{
  width: 5% !important;
}

.salary-ucl{
  width: 5% !important;
}

.salary-uil{
  width: 5% !important;
}

.salary-lp{
  width: 5% !important;
}

.salary-total_leaves{
  width: 7.9% !important;
}

.salary-total_net_leaves{
  width: 7.5% !important;
}

.salary-worked-days{
  width: 8.4% !important;
}

.salary-amount{
  width: 7.4% !important;
}

/* // Bank Details  */
.bank_bulk_transaction_type{
  width: 12% !important;
}

.punch-table{
  height: 350px !important;
  overflow-y: scroll;
  scrollbar-width:thin;
}
.punch-header{
  position: sticky;
  top: 0;
}

.pop-icon {
	color: white;
	position: absolute;
	top: -7.5%;
	right: 18.2%;

}
#salary-filter .salary-popup-form {
	background-color: white;
	width: 80%;
	position: absolute;
	top: 177px;
	right: 1.5%;
	box-shadow: 2px 2px 50px #8080807e;
	z-index: 99;
}
#Bank-filter .Bank-popup-form {
	background-color: white;
	width: 80%;
	position: absolute;
	top: 177px;
	right: 1.5%;
	box-shadow: 2px 2px 50px #8080807e;
	z-index: 99;
}

#punch-filter .popup-form{
  background-color: white;
	width: 80%;
	position: absolute;
	top: 177px;
	right: 1.5%;
	box-shadow: 2px 2px 50px #8080807e;
	z-index: 99;
}

.leave-history-table{
  height: 200px !important;
  overflow-y: scroll;
  scrollbar-width:thin;
}
.leave-history-slots {
  height: 350px !important;
  overflow-y: auto;
  scrollbar-width: thin;
}

.unApproved{
  background-color: red;
}

/* // Salary Slip */
.fixed-salary-slip-header {
	position: fixed;
	background-color: #981f77 !important;
	color: white;
	z-index: 40;
	/* top: 19.2%; */
	top: 120px;
  width: 92.3%;
  left: 3.8%;
}

.salary-slip-id{
  width: 5.5% !important;
}
.salary-slip-empcode{
  width: 8% !important;
}
.salary-slip-empname{
  width: 20% !important;
}
.salary-slip-department{
  width: 20% !important;
}
.salary-slip-designation{
  width: 20% !important;
}
.salary-slip-action{
  width: 8% !important;
}

.fixed-salary-slip-header .col-t-1{
  width: 7.1% !important;
}
.fixed-salary-slip-header .col-t-2{
  width: 10% !important;
}
.fixed-salary-slip-header .col-t-3{
  width: 20% !important;
}
.fixed-salary-slip-header .col-t-4{
  width: 20% !important;
}
.fixed-salary-slip-header .col-t-5{
  width: 20% !important;
}
.fixed-salary-slip-header .col-t-6{
  width: 8% !important;
}

.common-row{
  padding: 10px 0;

}
.small-section{
  border-top: 2px solid black;
}

.searcht-icon{
  position: absolute;
  top: 30%;
  left: 10%;
}
.srt-only {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
  left:20%;
	width: 1px;
}

#salary-filter .leave-popup-form {
	background-color: white;
	width: 80%;
	position: absolute;
  top: 65px;
  right: 7%;
	box-shadow: 2px 2px 50px #8080807e;
	z-index: 99;
}

/* // View  Credit Card Bill Popup */
.view-crdit-card{
	max-height: 300px !important;
	overflow-y: auto;
	scrollbar-width:thin;
  }
  
  .view-crdit-card::-webkit-scrollbar {
	width: 6px;
  }
  
  .view-crdit-card::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
  }
  
  .view-crdit-card::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
  }
  
  .view-crdit-card::-webkit-scrollbar-thumb:hover {
	background: #555;
  }

  /* // Punch Preview  */
  /* .pp_date{
    width: 12% !important;
  }
  .punch_in{
    width: 12% !important;
  }
  .punch_out{
    width: 12% !important;
  } */
  .customShortIcon{
    position: relative;
  }
  .customShortIcon span{
   position: absolute;
   top: 50%;
   right:10px;
   transform: translateY(-50%);
  }
