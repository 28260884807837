
.fix-introducer-header .t-col-2{
    width: 4%;
}
.fix-introducer-header .t-col-3 {
    width: 4%;
}

.fix-introducer-header .t-col-4 {
    width: 22%;
}
.fix-introducer-header .t-col-5 {
    width: 22%;
}
.fix-introducer-header .t-col-6 {
    width: 7%;
}
.fix-introducer-header .t-col-7 {
    width: 10%;
}
.fix-introducer-header .t-col-8 {
    width: 10%;
}
/* .fix-introducer-header .t-col-9 {
    width: 4%;
} */
.billTable-col-3{
    width: 6% !important;
}
.fix-credit-header {
	position: fixed;
	background-color: #981f77;
	color: white;
	z-index: 40;
	/* top: 19.2%; */
	top: 120px;
	width: 92%;
    left: 4.1%;
}
.fix-credit-header .billTable-col-1{
    width: 4% !important;
}
.fix-credit-header .billTable-col-2{
    width: 5% !important;
}
.fix-credit-header .billTable-col-3{
    width: 6% !important;
}
.fix-credit-header .billTable-col-4{
    width: 5% !important;
}
.fix-credit-header .billTable-col-5{
    width: 5% !important;
}

.budget_status{
    background-color: #5c038517;
    /* width: 22%; */
}
.budget_status:hover{
    background-color: #5c038533;
}

.category-col{
    width: 8% !important;
}
 
.project-col{
    width: 35% !important;
}
.cost-col{
    width: 6% !important;
}
.bal-col{
    width: 10% !important;
}
.approved-col{
    width: 10% !important;
}
.gst-col{
    width: 5% !important;
}
.tds-col{
    width: 5% !important
}

.export-btn{
    background-color: #038523d6 !important;
    text-transform: uppercase;
    color: white !important;
}

.btn-upload{
    background-color: #0f70d8d6 !important;
    text-transform: uppercase;
    color: white !important;
}

.type-col{
    width: 5% !important;
}

.Category-col{
    width: 5% !important;
}

.title-col{
    width: 30% !important;
}

.amount-col{
    width: 6% !important;
}


/* // Credit Card Section  */
/* .billTable-col-1{
    width: 4% !important;
}
.billTable-col-2{
    width: 8% !important;
}
.billTable-col-3{
    width: 2% !important;
}
.billTable-col-4{
    width: 8% !important;
}
.billTable-col-5{
    width: 8% !important;
}
.billTable-col-6{
    width:unset !important;
}
.billTable-col-7{
   width: 8% !important;
} */


/* // Filter  */
.expense-filter .popup-form{
    top: 92px;
    right: 0%;
}
.expense-filter .pop-icon {
    color: white;
    position: absolute;
    top: -7.7%;
    right: 9.2%;
}

.fix-crditcard-header{
    position: fixed;
    top: 27.2%;
    width: 91.5%;
    background-color: #981f77;
    color: white;
}

/* // Expense Table 
 */

 .exp-1{
    width: 4% !important;
 }
 .exp-2{
    width: 6% !important;
 }
 .exp-3{
    width: 8% !important;
 }
 .exp-4{
    width: 28% !important;
 }
 .exp-5{
    width: 25% !important;
 }
 .exp-6{
    width: 7% !important;
 }
 .exp-7{
    width: 7% !important;
 }
 .exp-8{
    width: 6% !important;
 }
 .exp-9{
    width: 7% !important;
 }

.credit-card-btn{
    width: 90%;
}

@media screen and (max-width : 1600px) {
    /* .credit-card-btn{
        width: 100%;
    } */
    .credit-card-btn{
        width: 100% !important;
    }
    .upload-bill-btn{
        width: 100% !important;
    }
}
@media screen and (max-width : 1400px) {
    /* .credit-card-btn{
        width: 100%;
    } */
     .btn-container{
        justify-content: center !important;
     }
    .credit-card-btn{
        width: 110% !important; 
    }
    .upload-bill-btn{
        width: 110% !important;
        margin-left: 10px !important;
        
    }
    .view-bill{
        width: 100% !important;
        margin-left: 10px;
        margin-right: 10px;
        padding-right: 10px !important;
    }

    .bank-bal-box h6{
        padding-bottom: 16px;
    }
    
}

@media screen and (min-width: 1000px) and (max-width : 1460px){
    .lm-balance h6{
        padding-bottom: 15px;
    }
   
    .export-csv-btn{
        width: 32% !important;
    }
    .view-bill-btn{
        width: 32% !important;
    }
    .upload-bill{
        width: 32% !important;
        margin-right: 10px;
    }
}
@media screen and (min-width: 1000px) and (max-width : 1290px){
    .total-expesne{
        width: 22% !important;
    }
   .total-credit-card{
    width: 22% !important;
   }
    
}
@media screen and (min-width: 1300px) and (max-width : 1460px){
    .cm-balance h6{
        padding-bottom: 15px;
    }

    .total-badget{
        width: 22% !important;
    }
}

.custom-select-height {
    height: 30px; /* Adjust this value as needed */
    line-height: 25px; /* Ensure text is vertically centered */
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
.select-wrapper{
    position: relative;
  
}