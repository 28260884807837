.fixed-setting-header-credential {
  position: fixed;
  top: 125px !important;
  background-color: #981f77;
  color: white;
  z-index: 40 !important;
  width: 87%;
}

#credentialPage {
  .fixed-buttons {
    top: 118px;
  }

  /* .copy-popup {
    line-height: 1rem;
    position: absolute;
    right: 0;
    margin-top: -13%;
    display: inline-block;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    font-weight: 600;
    color: rgb(255, 255, 255);
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 50px;
    background-image: linear-gradient(rgb(124, 138, 255), rgb(60, 79, 224));
    box-shadow: rgba(37, 44, 97, 0.15) 0px 4px 11px 0px, rgba(93, 100, 148, 0.2) 0px 1px 3px 0px;
    transition: 0.2s ease-out;
    width: 25%;
    text-align: center;
  }

  .copy-popup::after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 30%;
    transform: translateX(-50%);
    border-width: 0 7px 7px;
    rotate: 180deg;
    border-style: solid;
    border-color: transparent transparent rgb(124, 138, 255);
  } */

  th.sorting {
    position: relative;
    cursor: pointer;
  }

  th.sorting_asc::after {
    content: '▲';
    /* Unicode or symbol for ascending */
    font-size: 0.8em;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  th.sorting_desc::after {
    content: '▼';
    /* Unicode or symbol for descending */
    font-size: 0.8em;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .copyMsg,
  .copyMsg2 {
    top: -40%;
    right: -4%;
    font-weight: 600;
    background-color: #af27ee !important;
    background-color: rgb(90, 57, 121) !important;
    color: white !important;
    padding: 3px 10px;
    border-radius: 5px;
  }

  .copyMsg::after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 30%;
    transform: translateX(-50%);
    border-width: 0 7px 7px;
    rotate: 180deg;
    border-style: solid;
    border-color: transparent transparent rgb(90, 57, 121);
  }

  .fa-lg {
    font-size: 1.1em;
    line-height: .75em;
    vertical-align: -.0667em;
    color: rgba(119, 117, 117, 0.74);
  }

}

.copyMsg2 {
  top: -3%;
  right: 11.5%;
  font-weight: 600;
  background-color: #af27ee !important;
  background-color: rgb(90, 57, 121) !important;
  color: white !important;
  padding: 3px 10px;
  border-radius: 5px;
}

.copyMsg2::after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 30%;
  transform: translateX(-50%);
  border-width: 0 7px 7px;
  rotate: 180deg;
  border-style: solid;
  border-color: transparent transparent rgb(90, 57, 121);
}


.width-auto-cred {}

/* if length === 7 */

#credential-table .special-class-7-length-t-1 {
  width: 2% !important;
}

#credential-table .special-class-7-length-t-2 {
  width: 7% !important;
}

#credential-table .special-class-7-length-t-3 {
  width: 7% !important;
}

#credential-table .special-class-7-length-t-4 {
  width: 7% !important;
}

#credential-table .special-class-7-length-t-5 {
  width: 7% !important;
}

#credential-table .special-class-7-length-t-6 {
  width: 7% !important;
}

#credential-table .special-class-7-length-t-7 {
  width: 4% !important;
}

#credential-table .special-class-7-length-head-t-1 {
  width: 3% !important;
}

#credential-table .special-class-7-length-head-t-2 {
  width: 5% !important;
}

#credential-table .special-class-7-length-head-t-3 {
  width: 5% !important;
}

#credential-table .special-class-7-length-head-t-4 {
  width: 5% !important;
}

#credential-table .special-class-7-length-head-t-5 {
  width: 5% !important;
}

#credential-table .special-class-7-length-head-t-6 {
  width: 5% !important;
}

#credential-table .special-class-7-length-head-t-7 {
  width: 5% !important;
}

/* if length === 6 */

#credential-table .special-class-6-length-t-1 {
  width: 4% !important;
}

#credential-table .special-class-6-length-t-2 {
  width: 11% !important;
}

#credential-table .special-class-6-length-t-3 {
  width: 11% !important;
}

#credential-table .special-class-6-length-t-4 {
  width: 11% !important;
}

#credential-table .special-class-6-length-t-5 {
  width: 11% !important;
}

#credential-table .special-class-6-length-t-6 {
  width: 5% !important;
}

#credential-table .special-class-6-length-head-t-1 {
  width: 2.8% !important;
}

#credential-table .special-class-6-length-head-t-2 {
  width: 8.2% !important;
}

#credential-table .special-class-6-length-head-t-3 {
  width: 8.2% !important;
}

#credential-table .special-class-6-length-head-t-4 {
  width: 8.2% !important;
}

#credential-table .special-class-6-length-head-t-5 {
  width: 8.2% !important;
}

#credential-table .special-class-6-length-head-t-6 {
  width: 6% !important;
}

/* if length === 5 */

#credential-table .special-class-5-length-t-1 {
  width: 4% !important;
}

#credential-table .special-class-5-length-t-2 {
  width: 14% !important;
}

#credential-table .special-class-5-length-t-3 {
  width: 14% !important;
}

#credential-table .special-class-5-length-t-4 {
  width: 14% !important;
}

#credential-table .special-class-5-length-t-5 {
  width: 5% !important;
}

#credential-table .special-class-5-length-head-t-1 {
  width: 2.4% !important;
}

#credential-table .special-class-5-length-head-t-2 {
  width: 9.4% !important;
}

#credential-table .special-class-5-length-head-t-3 {
  width: 9.4% !important;
}

#credential-table .special-class-5-length-head-t-4 {
  width: 9.4% !important;
}

#credential-table .special-class-5-length-head-t-5 {
  width: 5% !important;
}

/* .......................................... */

/* if length === 4 */

#credential-table .special-class-4-length-t-1 {
  width: 4% !important;
}

#credential-table .special-class-4-length-t-2 {
  width: 20% !important;
}

#credential-table .special-class-4-length-t-3 {
  width: 20% !important;
}

#credential-table .special-class-4-length-t-4 {
  width: 4% !important;
}

#credential-table .special-class-4-length-head-t-1 {
  width: 2.4% !important;
}

#credential-table .special-class-4-length-head-t-2 {
  width: 14% !important;
}

#credential-table .special-class-4-length-head-t-3 {
  width: 14% !important;
}

#credential-table .special-class-4-length-head-t-4 {
  width: 4.7% !important;
}

/* ...................................................... */

/* if length === 3 */

#credential-table .special-class-3-length-t-1 {
  width: 4% !important;
}

#credential-table .special-class-3-length-t-2 {
  width: 20% !important;
}

#credential-table .special-class-3-length-t-3 {
  width: 4% !important;
}

#credential-table .special-class-3-length-head-t-1 {
  width: 3.4% !important;
}

#credential-table .special-class-3-length-head-t-2 {
  width: 17% !important;
}

#credential-table .special-class-3-length-head-t-3 {
  width: 4.7% !important;
}

/* ...................................................... */

.width-5 {
  width: 5.33333333% !important;
}

.width-94.col-md-11 {
  width: 93.66666667%;
  /* min-height: 100vh; */
}

.width-16 {
  width: 15.7%;
}

.width-5 li {
  width: 100% !important;
}

.fixed-side-tab-cred {
  position: fixed;
  top: 19.2%;
}

/* .img-container{
  position: relative;
} */
.editTabImg {
  position: fixed;
  width: 26%;
  bottom: 0;
  transform: translateY(-20%);
  transition: all 5s ease;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.075) !important;
  padding: 0 1rem;
}

.fixed-setting-header-cred-setting {
  position: fixed;
  background-color: #1e5ed4;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 49%;
  left: 46.6%;
}

.fixed-setting-header-cred-setting .col-md-1 {
  width: 3%;
}

.fixed-setting-header-cred-setting .col-md-9 {
  width: 43%;
}

.fixed-setting-header-cred-setting .col-md-2 {
  width: 10%;
}

.col-hover {
  position: relative;
  cursor: pointer;
}

.col-hover:hover .btnCopy {
  display: inline-block !important;
}

/* .col-hover:hover .btnCopy-ftpUser{
  display: inline-block !important;
} */
.ftpHostUrl {
  text-overflow: ellipsis;
}

.btnCopy {
  display: none !important;
  position: absolute;
  right: 10%;
  top: 8%;
}

/* .btnCopy-ftpUser{
  display: none !important;
  position: absolute;
  right: 30%;
  top: 8%;
} */
.fa-copy {
  /* color: #4d1b62; */
  color: rgb(90, 57, 121);
  font-size: 16px;
}

.copy-popup {
  left: 50%;
  top: 0%;
  font-weight: 600;
  background-color: #af27ee !important;
  color: white !important;
  padding: 5px 15px;
  border-radius: 5px;
}

.sidebar-container {
  width: 10px !important;
  /* background-color: grey; */
}

.crendential {
  font-size: 13px !important;
  position: relative;
  background-color: rgb(161, 86, 196) !important;
  color: white !important;
}

/* #credentialPage .crendential.active {
  background-color: rgba(92, 3, 133, 0.84) !important;
} */
.crendential .link_name {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  /* left: 58px; */
  z-index: 1000;
  white-space: nowrap;
  word-wrap: unset;
  width: 200px;
  background-color: rgba(92, 3, 133, 0.84);
  color: white;
  padding: 8px 8px;
  transition: opacity 0.3s ease;
}

.crendential:hover .link_name {
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.credential-table {
  width: 95.5% !important;
}


#movable-popup {
  padding: 0;
  border-radius: .6rem;
  .popup-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
    border-color: rgb(239, 242, 247) !important;
  }


  .react-resizable {
    padding: 0px !important;

  }

  .popup-content {
    padding: 1rem;
  }

  .popup-title {
    color: rgb(73, 80, 87) !important;
  }
  .form-label {
    color: #495057;
  }
}

@media screen and (min-width: 1900px) {
  #credentialPage {
    .fixed-setting-header-credential {
      top: 13.2%;

    }

    .fixed-buttons {
      top: 12.2%;
    }

    .fixed-setting-header-credential {
      width: 88%;
    }

    #credential-table .special-class-2-length-head-t-1 {
      width: 4%;
    }

    #credential-table .special-class-2-length-head-t-2 {
      width: 4%;
    }

    #credential-table .special-class-6-length-head-t-1 {
      width: 3.2% !important;
    }

    #credential-table .special-class-6-length-head-t-2 {
      width: 9% !important;
    }

    #credential-table .special-class-6-length-head-t-3 {
      width: 9.2% !important;
    }

    #credential-table .special-class-6-length-head-t-4 {
      width: 9.2% !important;
    }

    #credential-table .special-class-6-length-head-t-5 {
      width: 9.1% !important;
    }

    #credential-table .special-class-6-length-head-t-6 {
      width: 5% !important;
    }

    #credential-table .special-class-5-length-head-t-1 {
      width: 3% !important;
    }

    #credential-table .special-class-5-length-head-t-2 {
      width: 10.4% !important;
    }

    #credential-table .special-class-5-length-head-t-3 {
      width: 10.6% !important;
    }

    #credential-table .special-class-5-length-head-t-4 {
      width: 10.4% !important;
    }

    #credential-table .special-class-5-length-head-t-5 {
      width: 4.6% !important;
    }

  }

}

/* @media screen and (min-width: 1400px){
  #credentialPage {
    .fixed-setting-header-credential {
      top: 13.2%;

    }
    .fixed-buttons {
      top: 12.2%;
    }
  }
} */
