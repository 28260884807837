.id_box{
    width: 5% !important;
}
.festival_name_box{
    width: 15% !important;
}
.date_box{
    width: 12% !important;
}
.day_box{
    width: 12% !important;
}
.comment_box{
    width: unset !important;
}
.festival_action_box{
    width: 10% !important;
}