.location-img {
  object-fit: cover;
}

.assigned-img {
  border-radius: 50px;
}

.contact-form {
  width: 100%;
}

.contact-btns {
  width: 4%;
}

.contact-column {
  width: 30%;
  padding-right: 16px;
}

.contact-column:last-child {
  padding-right: 0;
}

.big-checkbox {
  width: 20px !important;
  height: 20px !important;
}

.contact-column .form-check-input:checked,
.notes .form-check-input:checked {
  background-color: #556ee6 !important;
  border-color: #556ee6;
}

.contact-column .form-check-input,
.notes .form-check-input {
  background-color: #fff !important;
  border: 2px solid #ced4da;
}

/* Edit page */
.edit-contact-column {
  width: 30%;
}

.dashed-border-bottom {
  border-bottom: 2px dashed lightgray;
}

.verti-timeline {
  border-left: 3px solid var(--bs-gray-400) !important;
  padding: 20px 0 15px 0;
  margin-left: 100px !important;
}

.timeline-icon {
  transform: translateX(-2px);
}

.timing {
  position: absolute;
  left: -9%;
  top: 0;
}

.border-left {
  border-left: 3px solid var(--bs-gray-400) !important;
}

.userImg {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px;
  border: 3px solid lightgray;
  position: relative;
  right: 20%;
}

.notes-box {
  margin-left: 20px !important;
  padding: 0 !important;
}

.note {
  background-color: #f3f6f9;
  position: relative;
}

.note-icon {
  color: #f3f6f9;
  position: absolute;
  top: 20%;
  left: -1.2%;
  transform: rotate(-90deg);
}

.edit-delete-btn {
  background-color: #fff !important;
  padding: 8px;
  border-radius: 5px !important;
  font-size: 13px !important;
}

.files-table .fix-header {
  width: 74.6%;
  left: 21%;
}

.payment-table {
  table-layout: fixed;
}

.payment-table .fix-header {
  /* width: 74.6%;
  left: 21%; */
  width: 75.8%;
  left: 20.4%;
}

.payment-table .fix-header tr th {
  width: 25%;
}

/* hover card */
.profileImg-box {
  position: relative;
  cursor: pointer;
}

.profileImg-detail {
  position: absolute;
  width: 300px;
  top: 0;
  left: 100%;
  z-index: 40;
  box-shadow: 2px 2px 50px #1111113d;
  display: none;
  padding-bottom: 0;
}

.profileImg-box:hover .profileImg-detail {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.notes-sidebar {
  position: fixed;
  z-index: 1000;
  padding: 20px;
  background-color: white;
  box-shadow: 2px 2px 50px #11111142;
  width: 75%;
  top: 22%;
  right: -75%;
  transition: 0.4s;
}

.show-notes-sidebar {
  right: 0%;
}

.verti-timeline2 {
  border-left: 3px dashed #eff2f7 !important;
  border-left: 3px dashed var(--bs-border-color) !important;
  margin: 0 10px !important;
}
.verti-timeline2 .event-list {
  padding: 0px 0px 25px 30px !important;
}
.no-padding-desc p {
  margin-bottom: 0px !important;
}

/* Recording section -> Notes  */
.recording-box {
  background-color: rgba(255, 0, 0, 0.1); /* Light red background to indicate recording */
  border: 2px solid red; /* Red border to emphasize the recording state */
  padding: 8px 16px;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.3); /* Add a shadow to create a recording effect */
  /* animation: pulse 1.5s infinite; */
}

.recording-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* for animation */
.recording-blink {
  animation: blink 1s infinite; 
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.text-danger1 {
  color: red !important; 
}
