.dynamic-fields {
  height: 215px;
  overflow-y: auto;
  overflow-x: hidden;
}
.border-bottom {
  border-bottom: 1px solid #eff2f7;
}


/* .fixed-s-setting-header{
  position: fixed;
  top: 25%;
  width: 50%;
  background-color: #981f77;
  color: white;
} */

.fixed-csetting-header tr {
  display: flex;
}
.fixed-setting-industry-header {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 49%;
  /* left: 35.4%; */
}

.fixed-setting-col-header .col-t-1 {
  width: 4.5%;
}
.fixed-setting-col-header .col-t-3 {
  width: 10%;
}
.fixed-setting-col-header .col-t-2 {
  width: 46.5%;
}
.departImg {
  position: fixed;
  width: 25%;
  top: 16%;
  transition: all 5s ease;
  /* transform: translateY(-20%); */
  /* transition: all 5s ease; */
  /* box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; */
  padding: 0 1rem;
}
.departImg img {
  transition: all 5s ease;
  box-shadow: 0 0.75rem 2rem rgba(0, 0, 0, 0.125) !important;
}

@media screen and (min-width: 1600px) {
  .fixed-setting-industry-header {
    width: 50.2%;
  }
  .fixed-setting-col-header .col-t-1 {
    width: 3.8%;
  }
  .fixed-setting-col-header .col-t-2 {
    width: 36.3%;
  }
  .fixed-setting-col-header .col-t-3 {
    width: 8%;
  }
}

.info-icon{
  color: rgb(200, 200, 200) !important;
}
/* //================ Department Table  //================*/
.department_id{
  width: 12% !important;
}
.department_name{
  width: unset;
}
.action-width-roles{
  width: 16% !important;
}

.fixed-csetting-header {
  position: fixed;
  top: 120px;
  width: 50%;
  background-color: #981f77;
  color: white;
}
.fixed-csetting-header .department_id{
  width: 6%;
}
.fixed-csetting-header .department_name{
  width: 73%;
}

@media screen and (min-width : 1200px) and (max-width :1600px) {
  .fixed-csetting-header {
    position: fixed;
    top: 25%;
    width: 49%;
    background-color: #981f77;
    color: white;
  }
  .department_id{
    width: 15% !important;
  }
  .department_name{
    width: 67% !important;
  }
  .action-width-roles{
    width: 18% !important;
  }
  .fixed-csetting-header .department_id{
    width: 15% !important;
  }
  .fixed-csetting-header .department_name{
    width: 67% !important;
  }
}

/* //================ Shift Table  //================*/

.fixed-s-setting-header{
  position: fixed;
  top: 120px;
  width: 50%;
  background-color: #981f77;
  color: white;
}

.shift_id{
  width: 12%;
}
.fixed-s-setting-header .shift_id{
  width: 3.5% !important;
}
.fixed-s-setting-header .shift-name-width{
  /* width: 11.5% !important; */
  width: 9.2% !important;
}
.fixed-s-setting-header .shift-time-width{
  width: 14.2% !important;
}
.fixed-s-setting-header .action-width-shift{
  width: 3% !important;
}

@media screen and (min-width : 1200px) and (max-width :1600px){

  .fixed-s-setting-header{
    position: fixed;
    top: 120px;
    width: 50%;
    background-color: #981f77;
    color: white;
  }  
  .fixed-csetting-header {
    position: fixed;
    top: 120px;
    width: 49%;
    background-color: #981f77;
    color: white;
  }
  .shift_id{
    width: 15%;
  }
  .fixed-s-setting-header .shift_id{
    width: 4.6% !important;
  }
  .fixed-s-setting-header .shift-name-width{
    width: 11% !important;
  }
  .fixed-s-setting-header .shift-time-width{
    width: 16.5% !important;
  }
  .fixed-s-setting-header .action-width-shift{
    width: 2% !important;
  }
}
/* .shift_id{
  width: 11% !important;
} */
/* .shift-name-width{
  width: 45% !important;
} */
/* .shift-time-width{
  width: 15% !important;
} */
.action-width-shift{
  width: 14% !important;
}


.customShortIcon{
  position: relative;
}
.customShortIcon span{
 position: absolute;
 top: 50%;
 right:10px;
 transform: translateY(-50%);
}
/* //================ Category Table  //================*/

.fixed-category-setting-header{
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 75.5%;
}

 .categ-id{
  width: 7% !important;
} 

.categ-name{
  width: 35% !important;
}

.categ-type{
  width: 35% !important;
}

.categ-action{
  width: 9% !important;
}

.fixed-category-setting-header .categ-id{
  width: 5% !important;
}
.fixed-category-setting-header .categ-name{
  width: 25% !important;
}
.fixed-category-setting-header .categ-type{
  width: 25% !important;
}
.fixed-category-setting-header .categ-action{
  width: 5% !important;
}

/* //================ Payment Mode Table  //================*/


.fixed-pay-setting-header{
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 75.5%;
}

.pay-id{
  width: 7% !important;
} 

.pay-type{
  width: 35% !important;
}

.pay-mode{
  width: 35% !important;
}
.pay-action{
  width: 9% !important;
}

.fixed-pay-setting-header .pay-id{
  width: 5% !important;
}
.fixed-pay-setting-header .pay-type{
  width: 25% !important;
}
.fixed-pay-setting-header .pay-mode{
  width: 25% !important;
}
.fixed-pay-setting-header .pay-action{
  width: 5% !important;
}

/* //================ Other Expense Table  //================*/

.fixed-oe-setting-header{
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 75.5%;
}

.oe-id{
  width: 7% !important;
} 

.oe-name{
  width: 35% !important;
}

.oe-amount{
  width: 35% !important;
}
.oe-action{
  width: 9% !important;
}

.fixed-oe-setting-header .oe-id{
  width: 5% !important;
}
.fixed-oe-setting-header .oe-name{
  width: 25% !important;
}
.fixed-oe-setting-header .oe-amount{
  width: 25% !important;
}
.fixed-oe-setting-header .oe-action{
  width: 5% !important;
}












