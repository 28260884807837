.Credentials-table .fixed-setting-header {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 49.4%;
  left: 46.4%;
}

.generalTaskSetting{
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 65.1%;
  left: 27.4%;
}

.Credentials-table .t-col-1 {
  width: 10% !important;
}

.Credentials-table .t-col-2 {
  width: 70% !important;
}

.Credentials-table .t-col-3 {
  width: 20% !important;
}

/* General Setting / General Task CSS  */

.gtSidebar {
  margin-top: 18% !important;
}

.generalTaskSetting .gt_id{
  width: 7.5% !important;
}
.gt_id {
  width: 8% !important;
  text-align: center;
}


.generalTaskSetting .gt_name{
  width: 80% !important;
}
.gt_name {
  width: 80% !important;
}

.generalTaskSetting .gt_action{
  width: 6% !important;
}
.gt_action {
  width: 10% !important;
  text-align: center;
}

.report-reason {
  width: 55% !important;
}

.report-option {
  width: 10% !important;
}

.report-by {
  width: 15% !important;
}

.employee {
  width: 8% !important;
}

.bg-orange {
  background-color: #ffe8bf52 !important;
}

.report-filter {
  position: fixed;
  top: 32%;
  right: 2%;
  background-color: #fff;
  min-width: 96%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  border-radius: 5px;
}

.acc_id{
  width: 8% !important;
  text-align: center;
}

.acc_name{
  width: 80% !important;
  text-align: start ;
}
.acc_action{
  width: 6% !important;
  text-align: center;
}

@media screen and (min-width : 1300px) and (max-width : 1400px) {
  .generalTaskSetting .gt_id{
    width: 8% !important;
  }
  .generalTaskSetting .gt_name{
    width: 80% !important;
  }
  .generalTaskSetting .gt_action{
    /* width: 7.86% !important; */
    width: 7.92% !important;
  }

}


@media screen and (min-width : 1400px) and (max-width : 1500px) {
  .generalTaskSetting .gt_id{
    /* width: 9% !important; */
    width: 8% !important;
  }
  .generalTaskSetting .gt_name{
    width: 80% !important;
  }
  .generalTaskSetting .gt_action{
    width: 7.4% !important;
  }

}


@media screen and (min-width : 1900px) and (max-width : 2000px) {
  .generalTaskSetting {
    position: fixed;
    background-color: #981f77;
    color: white;
    z-index: 40;
    top: 19.2%;
    top: 120px;
    width: 65.1%;
    left: 26.9%;
  }
  .generalTaskSetting .gt_id{
    width: 8.5% !important;
  }
  .generalTaskSetting .gt_name{
    width: 80% !important;
  }
  .generalTaskSetting .gt_action{
    width: 5.6% !important;
  }

}
.general-tak a{
  color: #495057;
}
.subtask-title {
  font-size: 12px;
}