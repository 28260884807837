/* Google Fonts Import Link */

/* // Task Custom Select  */
.custom-select-wrapper {
  position: relative;
  display: inline-block;
}

.custom-select {
  appearance: none;
  /* Remove default styling */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  width: 200px;
}

.work-status{
  width: 15%;
  background-color: #5c038517;
}

.custom-rselect-option{
  width: 60% !important;
}

.custom-select:focus {
  border-color: #007bff;
  outline: none;
}

.custom-select option {
  background-color: #f8f9fa;
  /* Default option background */
  color: #000;
  /* Default option text color */
}

.status_inprogress {
  background-color: #343a40;
  /* Dark background */
  color: #fff;
  /* White text */
}

.status_await {
  background-color: #ffc107;
  /* Yellow background */
  color: #fff;
  /* White text */
}

.status_complete {
  background-color: #28a745;
  /* Green background */
  color: #fff;
  /* White text */
}

/* Table Header  */




.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #11101d;
  z-index: 100;
  transition: all 0.5s ease;
}

.sidebar.close {
  width: 78px;
}

.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}

.sidebar .logo-details i {
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}

.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}

.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}

.sidebar.close .nav-links {
  overflow: visible;
}

.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links li:hover {
  background: #1d1b31;
}

.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar.close .nav-links li .iocn-link {
  display: block
}

.sidebar .nav-links li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}

.sidebar.close .nav-links i.arrow {
  display: none;
}

.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .nav-links li a .link_name {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}

.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}

.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}

.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}

.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.one {
  width: 80%;
  margin-left: 10%;
  background-color: black;
  height: 400px;
}

.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}

.sidebar.close .profile-details {
  background: none;
}

.sidebar.close .profile-details {
  width: 78px;
}

.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}

.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}

.sidebar.close .profile-details img {
  padding: 10px;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}

.sidebar .profile-details .job {
  font-size: 12px;
}

.home-section {
  position: relative;
  background: #E4E9F7;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
}

.sidebar.close~.home-section {
  left: 78px;
  width: calc(100% - 78px);
}

.home-section .home-content {
  height: 60px;
  display: flex;
  align-items: center;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}

.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}

.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

/* // ===================== Add Projects Fixed Buttons  */

.fixed-proj-buttons {
  align-items: flex-end;
  min-width: 50px;
  position: fixed;
  right: 0;
  top: 14.2%;
  z-index: 50;
}

.activeProjectTab {
  background-color: #752997;
  color: white;
}

.activeProjectTab:hover {
  background-color: #DECCE7;
  color: #752997;
}

.proposal-status:hover {
  background-color: #DECCE7;
  color: #752997;
}

/* ======================================== Fixed Project Section ======================================== */


/* .fix-project-header{
} */
.fix-DR-project-header {
  position: fixed;
  background-color: #981f77 !important;
  color: white;
  z-index: 40;
  /* top: 19.2%; */
  top: 120px;
  width: 84.4%;
  left: 10.4%;
}

.fix-DR-project-header .t-col-1 {
  min-width: 5.5% !important;
}

.fix-DR-project-header .t-col-2 {
  min-width: 27.6% !important;

}

.fix-DR-project-header .t-col-3 {
  min-width: 22.8% !important;
}

.fix-DR-project-header .t-col-4 {
  min-width: 7.5% !important;

}

.fix-DR-project-header .t-col-5 {
  width: 6% !important;

}

.fix-DR-project-header .t-col-6 {
  width: 5.5% !important;

}

.fix-DR-project-header .t-col-7 {
  width: 13% !important;
}

.fix-DR-project-header .t-col-8 {
  width: 9% !important;
}

.fix-fixed-project-header {
  position: fixed;
  background-color: #981f77 !important;
  color: white;
  z-index: 40;
  /* top: 19.2%; */
  top: 120px;
  width: 84.4%;
  left: 10.4%;
}

.fix-fixed-project-header .t-col-1 {
  min-width: 5.5% !important;
}

.fix-fixed-project-header .t-col-2 {
  min-width: 25.7% !important;

}

.fix-fixed-project-header .t-col-3 {
  min-width: 21.58% !important;
}

.fix-fixed-project-header .t-col-4 {
  min-width: 7% !important;

}

.fix-fixed-project-header .t-col-5 {
  min-width: 7.5% !important;

}

.fix-fixed-project-header .t-col-6 {
  min-width: 5% !important;

}

.fix-fixed-project-header .t-col-7 {
  min-width: 5.010% !important;

}

.fix-fixed-project-header .t-col-8 {
  min-width: 13.050% !important;


}

.fix-fixed-project-header .t-col-9 {
  min-width: 5.025% !important;

}

.fix-fixed-project-header .t-col-10 {
  min-width: 9% !important;
}

/* //Support Table from Maintenace */

.fix-mtask-header {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 18.8%;
  /* top: 60px; */
  width: 73.2%;
  left: 0%;
}

.fix-mtask-header .t-col-1 {
  width: 6.5% !important;
}

.m_id {
  width: 6.5% !important;
}

.fix-mtask-header .t-col-2 {
  width: 6.5% !important;
}

.assignedm_name_box {
  width: 6.5% !important;
}


.fix-mtask-header .t-col-3 {
  width: 7% !important;
}

.mtype_name_box {
  width: 7% !important;
}

.fix-mtask-header .t-col-4 {
  width: 13% !important;
}

.mcustomer_name_box {
  width: 15% !important;
}

.fix-mtask-header .t-col-5 {
  width: 13% !important;
}

.mtask_name_box {
  width: 15% !important;
}

.fix-mtask-header .t-col-6 {
  width: 11% !important;
}

.mstatus_box {
  width: 13% !important;
}

.fix-mtask-header .t-col-7 {
  width: 4% !important;
}

.priority_col_box {
  width: 6% !important;
}

.fix-mtask-header .t-col-8 {
  width: 7% !important;
}

.last_reply_col-box {
  width: 7% !important;

}

.fix-mtask-header .t-col-9 {
  width: 7% !important;
}

.created_at_col-box {
  width: 7% !important;
}

.fix-mtask-header .t-col-10 {
  width: 7% !important;
}

.time_taken_box {
  width: 8% !important;
}


.fix-mtask-header .t-col-11 {
  width: 9% !important;
}

.mtask_action {
  width: 6% !important;
}



.fix-task-header {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  /* top: 19.2%; */
  top: 120px;
  width: 73.2%;
  left: 21.8%;
}

.fix-task-header .t-col-1 {
  width: 5% !important;
}

.fix-task-header .t-col-2 {
  width: 26% !important;
}

.fix-task-header .t-col-3 {
  width: 20% !important;
}

.fix-task-header .t-col-4 {
  width: 7% !important;
}

.fix-task-header .t-col-5 {
  width: 7% !important;
}

.fix-task-header .t-col-6 {
  width: 5% !important;
}

.fix-task-header .t-col-7 {
  width: 5% !important;
}

.fix-task-header .t-col-8 {
  width: 13% !important;
}

.fix-task-header .t-col-9 {
  width: 5% !important;
}

.fix-task-header .t-col-10 {
  width: 9% !important;
}





.project_id {
  width: 5% !important;
}
.fix-fixed-project-header .project_name_box {
  width: 24% !important;
}
.fix-fixed-project-header .customer_name_box {
  width: 19.2% !important;
}
.fix-fixed-project-header .project_status_box {
  width: 12.5% !important;
}

.project_name_box {
  width: 25% !important;
}

.customer_name_box {
  width: 20% !important;
}

.start_date_box {
  width: 7.5% !important;
}

.due_date_box {
  width: 7% !important;
}

.pwd_box {
  width: 6% !important;
}

.projects_days_box {
  width: 6% !important;
}

.project_status_box {
  width: 13% !important;
}

.did_box {
  width: 5.3% !important;
}

.project_action {
  width: 9% !important;
}

@media screen and (max-width: 1600px) {
  .project_id {
    width: 5.9% !important;
  }

  .project_name_box {
    width: 25%;
  }

  .customer_name_box {
    width: 20%;
  }

  .start_date_box {
    width: 8% !important;
  }

  .due_date_box {
    width: 7%;
  }

  .pwd_box {
    width: 5%;
  }

  .projects_days_box {
    width: 5.7% !important;
  }

  .project_status_box {
    width: 13%;
  }

  .did_box {
    width: 5%;
  }

  .project_action {
    width: 9%;
  }
}

@media screen and (max-width: 1400px) {
  .project_id {
    width: 6.6% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .project_name_box {
    width: 20% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .customer_name_box {
    width: 17% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .start_date_box {
    width: 9% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .due_date_box {
    width: 10% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pwd_box {
    width: 9% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .projects_days_box {
    width: 8.2% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .project_status_box {
    width: 13% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .did_box {
    width: 8% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .project_action {
    width: 7% !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .fix-fixed-project-header .project_id {
    width: 6.5% !important;
  }

  .fix-fixed-project-header .project_name_box {
    width: 18% !important;
  }

  .fix-fixed-project-header .customer_name_box {
    width: 15% !important;
  }

  .fix-fixed-project-header .start_date_box {
    width: 9% !important;
  }

  .fix-fixed-project-header .due_date_box {
    width: 9% !important;
  }

  .fix-fixed-project-header .pwd_box {
    width: 7% !important;
  }

  .fix-fixed-project-header .projects_days_box {
    width: 7.2% !important;
  }

  .fix-fixed-project-header .project_status_box {
    width: 12% !important;
  }

  .fix-fixed-project-header .did_box {
    width: 6% !important;
   
  }

  .fix-fixed-project-header .project_action {
    width: 10% !important;
  }
}


/* // Ad task  */
.task_id {
  width: 5% !important;
}

.assign_name_box {
  width: 10% !important;
}

.assigned_name_box {
  width: 5% !important;
}

.task_name_box {
  width: 20% !important;
}

.milestone_box {
  width: 8% !important;
}

.status_box {
  width: 8% !important;

}

.days_box {
  width: 8% !important;
}

.task_action {
  width: 8% !important;
}

.project-timing {
  position: absolute;
  left: -7%;
  top: 0;
}

/* // New Task Modal  */
.new-task-modal {
  max-height: 700px !important;
  overflow-y: auto;
  scrollbar-width: none;
}

.new-task-modal::-webkit-scrollbar {
  width: 6px;
}

.new-task-modal::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.new-task-modal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.new-task-modal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.newTaskModal {
  height: 90vh !important;
}

/* // View Task popup  */
.task-col-1 {
  width: 5% !important;
}

.task-col-1 {
  width: 8% !important;
}

/* //------------------------------------------------------- */
/* #projectPage {
  .fixed-buttons {
    top: 118px;
  }
}
.sidebar-container{
  position: fixed !important;
  width: 20% !important;
} */

/* Styles for table header */
.fix-table-header {
  /* background-color: #fff !important; */
  background-color: #fff !important;
  color: black;
}

.fix-table-body tr.condition-25 {
  color: white;
  background: linear-gradient(to right, #C3585A 25%, #ebd418 100%) !important;
}

.fix-table-body tr.condition-50 {
  color: white;
  background: linear-gradient(to right, #C3585A 50%, #ebd418 100%) !important;
}

.fix-table-body tr.condition-75 {
  color: white;
  background: linear-gradient(to right, #C3585A 75%, #ebd418 100%) !important;
}

.fix-table-body tr.condition-100 {
  background: linear-gradient(90deg, #C3585A 100%, #ebd418 100%) !important;
  color: white;
}


.close-ticket-header h3{
  padding-left: 40px;
}
#support-filter .popup-form {
  background-color: white;
  width: 100%;
  position: absolute;
  top: 184px;
  right: 7%;
  box-shadow: 2px 2px 50px #8080807e;
  z-index: 99;
}

.highPriority {
  background-color: #ffe8e8 !important;
}

.uploaded_files_box{
  width: 30%;
  max-width: 60%;
}