.tox-toolbar__group .tox-tbtn--select span {
  color: #111;
}
.tox .tox-dialog-wrap {
  z-index: 1 !important;
}
.tox .tox-background-menu {
  z-index: 1 !important;
}
.tox-selected-menu {
  z-index: 2 !important;
}
/* tox-menu tox-collection tox-collection--list tox-background-menu */
/* tox-menu tox-collection tox-collection--list tox-selected-menu */
/* #tiny {
  .tox .tox-edit-area::before {
    border: 2px solid #632182 !important;
  }
} */
