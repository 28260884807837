/* fixed header styling */
.fix-customers-header .t-col-1 {
  width: 6%;
}

.fix-customers-header .t-col-2 {
  width: 27%;
}
.fix-customers-header .t-col-3 {
  width: 20%;
}

.fix-customers-header .t-col-4 {
  width: 20%;
}

.fix-customers-header .t-col-5 {
  width: 12%;
}

.fix-customers-header .t-col-6 {
  width: 15%;
  /* width: 20%; */
}

/* Edit - Proposal tab */
.proposal-colums .t-col-1 {
  width: 4% !important;
}

.proposal-colums .t-col-2 {
  width: 11%;
}
.proposal-colums .t-col-3 {
  width: 23%;
}

.proposal-colums .t-col-4 {
  width: 10%;
}

.proposal-colums .t-col-5 {
  width: 6%;
}

.proposal-colums .t-col-6 {
  width: 8%;
}

.proposal-colums .t-col-7 {
  width: 13% !important;
}

.proposal-colums .t-col-8 {
  width: 13%;
}
.proposal-colums .t-col-9 {
  width: 12%;
}

/* Edit - Contract tab */
.contract-colums .t-col-1 {
  width: 4% !important;
}

.contract-colums .t-col-2 {
  width: 8%;
}
.contract-colums .t-col-3 {
  width: 23%;
}

.contract-colums .t-col-4 {
  width: 9.5%;
}

.contract-colums .t-col-5 {
  width: 6%;
}

.contract-colums .t-col-6 {
  width: 11%;
}

.contract-colums .t-col-7 {
  width: 11% !important;
}

.contract-colums .t-col-8 {
  width: 11%;
}
.contract-colums .t-col-9 {
  width: 11%;
}

/*  */
.create-login-btn {
  width: 20% !important;
}
.password-field {
  width: 29.5% !important;
}

/* Edit Project  */

.proj-t-col-1 {
  width: 3%;
}
.proj-t-col-2 {
  width: 18%;
}
.proj-t-col-3 {
  width: 18%;
}
.proj-t-col-4 {
  width: 11%;
}
.proj-t-col-5 {
  width: 11%;
}
.proj-t-col-6 {
  width: 7%;
}
.proj-t-col-7 {
  width: 8%;
}
.proj-t-col-8 {
  width: 12%;
}
.proj-t-col-9 {
  width: 6%;
}
.proj-t-col-10 {
  width: 6%;
}
.input-location {
  width: 18px !important;
  height: 18px !important;
}
.support-assign-width {
  width: 11%;
}
.id-width-file {
  width: 5%;
}
.activity-log {
  max-width: 1000px !important;
}

.customShortIcon{
  position: relative;
}
.customShortIcon span{
 position: absolute;
 top: 50%;
 right:10px;
 transform: translateY(-50%);
}

/* Bookings  */
.fix-calling-header .bookings-id{
  width: 5% !important;
}
#bookings .t-column-2{
  width: 6% !important;
}

.fix-booking-header tr{
  display: flex;
}
.fix-booking-header {
	position: fixed;
	background-color: #981f77;
	color: white;
	z-index: 40;
	/* top: 19.2%; */
	top: 120px;
	width: 91.2%;
	left: 4.4%;
}


@media screen and (min-width :1200px) and (max-width : 1340px) {
  /* .customShortIcon{

  }
  .customShortIcon span{
    position: absolute;
    top: 82%;
    right:38%;
    transform: translateY(-50%);
    margin-top: 25px;
   } */

 

   #bookings .t-column-7 {
    width: 9% !important;
  }

  .fix-booking-header .t-column-9 {
    width: 8% !important;
  }
  .fix-booking-header.booking-date{
    width: 9% !important;
  }
  .fix-booking-header.booking-timezone{
    width: 8% !important;
  }
}


@media screen and (min-width :1340px) and (max-width : 1440px) {
  /* .customShortIcon{

  }
  .customShortIcon span{
    position: absolute;
    top: 82%;
    right:38%;
    transform: translateY(-50%);
    margin-top: 25px;
   } */

   #bookings .t-column-1 {
    width: 4.7% !important; 
  }
   #bookings .t-column-2 {
    width: 5% !important; 
  }
   #bookings .t-column-3 {
    width: 13% !important; 
  }

  #bookings .t-column-4 {
    width: 14.4% !important;
  }

  #bookings .t-column-5 {
    width: 11.2%;
  }

  #bookings .t-column-7 {
    width: 9% !important;
  }

  #bookings .t-column-8 {
    width: 10% !important;
  }

  #bookings .t-column-9 {
    width: 10% !important;
  }

  #bookings .t-column-10 {
    width: 7.5% !important;
  }

  .fix-booking-header .t-column-9 {
    /* width: 8% !important; */
    width: 10% !important;
  }
  .fix-booking-header.booking-date{
    /* width: 9% !important; */
    width: 10% !important;
  }
  .fix-booking-header.booking-timezone{
    width: 8% !important;
  }
}



@media screen and (min-width : 1441px) {
  .fix-booking-header {
    position: fixed;
    background-color: #981f77;
    color: white;
    z-index: 40;
    /* top: 19.2%; */
    top: 120px;
    width: 92.2%;
    left: 4%;
  } 

  .fix-calling-header .bookings-id{
    width: 5% !important;
  }
  .fix-calling-header .booking-type{
    width: 6% !important;
  }
  .fix-calling-header .booking-name{
    width: 12.5% !important;
  }

  /* #bookings .t-column-1 {
    width: 5% !important;
  } */
  /* .bookings-id{
    width: 5.5% !important;
  }
  .fix-calling-header .bookings-id{
    width: 5.5% !important;
  } */

  /* #bookings .t-column-2 {
    width: 5% !important;
  } */
  /* .bookings-id{
    width: 5% !important;
  }
  .fix-calling-header .bookings-id{
    width: 5% !important;
  } */

  #bookings .t-column-3 {
    width: 12.5% !important;
  }
  
  .fix-calling-header .t-column-3{
    width: 13% !important;
  }

  #bookings .t-column-4 {
    width: 15% !important;
  }
  
  #bookings .t-column-5 {
    width: 11% !important;
  }
  
  #bookings .t-column-6 {
    width: 12% !important;
  }
  
  #bookings .t-column-7 {
    width: 9% !important;
  }
  
  #bookings .t-column-8 {
    width: 11% !important;
  }
  
  #bookings .t-column-9 {
    width: 10% !important;
  }
}