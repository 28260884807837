#Permission .fix-header {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2% !important;
  /* top: 120px; */
  width: 91.2%;
  left: 4.4%;
}

#staff-permission .fixed-sp-header{
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  /* top: 16.2% !important; */
  top: 120px !important;
  width: 74.5%;
  left: 21.0%;
}

.fixed-sp-header {
  position: fixed;
  top: 120px;
  z-index: 40;
  width: 75.2%;
  /* background-color: whitesmoke; */
  background-color: #981f77;
  color: white !important;
}
#Roles .fixed-sp-header {
  left: 35.6%;
}
.fixed-sp-header tr {
  display: flex;
  justify-content: space-between;
}
.fixed-sp-header th {
  width: 12%;
}
.fixed-sp-header th:first-child {
  width: 33%;
}
.fixed-sp-header th:nth-child(7) {
  width: 8.5%;
}


/* #staff-permission .fixed-sp-header :first-child{
  width: 13% !important;
} */
@media screen and (min-width: 1460px){
  #staff-permission .fixed-sp-header{ 
    /* top: 13.2% !important; */
    top: 120px !important;
    width: 75.3%;
    left: 20.5%;
  
  }
}

