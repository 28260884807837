/* fixed header styling */
/* Expense or payment */
.fix-payment-header .t-col-1 {
  width: 4%;
}

.fix-payment-header .t-col-2 {
  width: 9%;
}

.fix-payment-header .t-col-3 {
  width: 9%;
}

.fix-payment-header .t-col-4 {
  width: 19%;
}

.fix-payment-header .t-col-5 {
  width: 8.5%;
}

.fix-payment-header .t-col-6 {
  width: 8%;
}

.fix-payment-header .t-col-7 {
  width: 10.5%;
}

.fix-payment-header .t-col-8 {
  width: 11%;
}

.fix-payment-header .t-col-9 {
  width: 5.5%;
}

.fix-payment-header .t-col-10 {
  width: 15.5%;
}

.payment-popup-form {
  width: 60%;
  top: 0;
  right: 15%;
}

.payment-popup-form .pop-icon {
  color: white;
  position: absolute;
  top: -12.5%;
  right: 8.5%;
}

.payment-amounts {
  margin-bottom: 40px;
  background-color: #f0e8f4;
  margin-right: 20px;
  padding: 10px 20px;
}

.payment-amounts p {
  font-size: 15px;
}

/* --------------------------------------- */
/* vendor pay fix header */
.fix-vendorpay-header .t-col-1 {
  width: 4%;
}

.fix-vendorpay-header .t-col-2 {
  width: 9%;
}

.fix-vendorpay-header .t-col-3 {
  width: 9%;
}

.fix-vendorpay-header .t-col-4 {
  width: 18%;
}

.fix-vendorpay-header .t-col-5 {
  width: 8%;
}

.fix-vendorpay-header .t-col-6 {
  width: 12%;
}

.fix-vendorpay-header .t-col-7 {
  width: 12%;
}

.fix-vendorpay-header .t-col-8 {
  width: 6%;
}

.fix-vendorpay-header .t-col-9 {
  width: 6%;
}

.fix-vendorpay-header .t-col-10 {
  width: 16%;
}

/* bank account fix header */
.fix-bank-header .t-col-1 {
  width: 6%;
}

.fix-bank-header .t-col-2 {
  width: 12%;
}

.fix-bank-header .t-col-3 {
  width: 16%;
}

.fix-bank-header .t-col-4 {
  width: 18%;
}

.fix-bank-header .t-col-5 {
  width: 22%;
}

.fix-bank-header .t-col-6 {
  width: 13%;
}

.fix-bank-header .t-col-7 {
  width: 13%;
}

.bank-popup-form .pop-icon {
  right: 32%;
}

/* credit card module */
.credit-popup-form {
  top: 0;
  width: 60%;
  right: 18%;
}

.credit-popup-form .pop-icon {
  right: 5.4%;
}

/* Gst page ================================================= */
.fix-gst-header .t-col-1 {
  width: 4%;
}

.fix-gst-header .t-col-2 {
  width: 16%;
}

.fix-gst-header .t-col-3 {
  width: 21%;
}

.fix-gst-header .t-col-4 {
  width: 13%;
}

.fix-gst-header .t-col-5 {
  width: 13%;
}

.fix-gst-header .t-col-6 {
  width: 11%;
}

.fix-gst-header .t-col-7 {
  width: 11%;
}

.fix-gst-header .t-col-8 {
  width: 11%;
}

.ztpl-popup-form {
  top: 0;
  z-index: 10;
}

.ztpl-popup-form .pop-icon {
  right: 25%;
}

/* TDS Fixed table headers */
.fixed-tds-table-header tr th {
  position: sticky;
  top: -0.5px;
  right: 0;
  left: 0;
  box-shadow: 0 2px 2px -1px #00000055;
  z-index: 1;
  background-color: #f2f2f2;
}

.fixed-tds-table-header {
  max-height: 65vh;
  scrollbar-width: none;
}

.table {
  position: relative !important;
  border-collapse: collapse;
  overflow: hidden scroll !important;
}

/* prf invoice view */
.invoice-line {
  height: 1px;
  background-color: #999;
  margin-top: 30px;
}

.invoice-page {
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

/* zithas Invoice  */
.fix-zithas-tech-header .t-col-1 {
  width: 6%;
}

.fix-zithas-tech-header .t-col-2 {
  width: 10%;
}

.fix-zithas-tech-header .t-col-3 {
  width: 12%;
}

.fix-zithas-tech-header .t-col-4 {
  width: 26%;
}

.fix-zithas-tech-header .t-col-5 {
  width: 10%;
}

.fix-zithas-tech-header .t-col-6 {
  width: 10%;
}

.fix-zithas-tech-header .t-col-7 {
  width: 11%;
}

.fix-zithas-tech-header .t-col-8 {
  width: 15%;
}
#budget-expense {
  .fix-header .t-col-1 {
    width: 5.2% !important;
  }
  .fix-header .t-col-2 {
    width: 6% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .fix-header .t-col-3 {
    width: 7.7% !important;
  }
  .fix-header .t-col-4 {
    width: 32.8% !important;
  }
  .fix-header .t-col-5 {
    width: 32.8% !important;
  }
  .fix-header .t-col-6 {
    width: 7.2% !important;
  }
  .fix-header .t-col-7 {
    width: 9% !important;
  }
}

#expense {
  .fix-header .t-col-1 {
    width: 4% !important;
  }
  .fix-header .t-col-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 6% !important;
  }
  .fix-header .t-col-3 {
    width: 8% !important;
  }
  .fix-header .t-col-4 {
    width: 28% !important;
  }
  .fix-header .t-col-5 {
    width: 25% !important;
  }
  .fix-header .t-col-6 {
    width: 7% !important;
  }
  .fix-header .t-col-7 {
    width: 7% !important;
  }
  .fix-header .t-col-8 {
    width: 6% !important;
  }
  .fix-header .t-col-9 {
    width: 7% !important;
  }
  .fix-introducer-header .t-col-7 {
    width: 9% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}


@media screen and (max-width: 1400px) {
  .expense-filter .pop-icon {
    right: 12.4% !important;
  }
}
@media screen and (max-width: 1600px) {
  #budget-expense {
    .pop-icon {
      display: none;
    }
  
    .t-col-1 {
      width: 6% !important;
      min-width: fit-content !important;
    }
  
    .t-col-2 {
      width: 8.5% !important;
      min-width: fit-content !important;
      padding-left: 3px !important;
      padding-right: 3px !important;
    }
  
    .t-col-3 {
      width: 23% !important;
    }
  
    .t-col-4 {
      width: 23% !important;
    }
  
    .t-col-5 {
      width: 10% !important;
    }
  
    .t-col-6 {
      width: 10% !important;
  
    }
  
    .t-col-7 {
      width: 10% !important;
      min-width: fit-content !important;
  
    }
      
    .fix-header .t-col-1 {
      width: 6.5% !important;
      min-width: fit-content !important;
    }
  
    .fix-header .t-col-2 {
      width: 9.5% !important;
      min-width: fit-content !important;
    }
  
    .fix-header .t-col-3 {
      width: 25.5% !important;
      min-width: fit-content !important;
    }
  
    .fix-header .t-col-4 {
      width: 25.5% !important;
      min-width: fit-content !important;
  
    }
  
    .fix-header .t-col-5 {
      width: 10.8% !important;
      min-width: fit-content !important;
  
    }
  
    .fix-header .t-col-6 {
      width: 11.3% !important;
      padding-left: 3px !important;
      padding-right: 3px !important;
    }
  
    .fix-header .t-col-7 {
      width: 10.9% !important;
      min-width: fit-content !important;
    }  
  }
}
