/* @import "../../node_modules/@syncfusion/ej2/material.css"; */
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

/* //============== To Disable Select //============== */

#ViewProjectDetails .select__multi-value__remove.css-12a83d4-MultiValueRemove,
#ViewProjectDetails .select__indicators {
  display: none !important;
}

.disabled-editor .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
.disabled-editor .ck.ck-toolbar.ck-toolbar_grouping {
  pointer-events: none;
  /* Disable all mouse interactions */
}

.disabled-editor .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  background-color: #eff2f7 !important;
}

.note-sticky-form .ck.ck-editor__main>.ck-editor__editable {
  /* height: 365px !important; */
}

.react-resizable {
  position: relative;
  background-color: #fff;
  padding: 20px;
  box-shadow: 2px 2px 20px #999999a5;
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-resizable-handle {
  position: absolute;
  width: 30px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+);
  background-position: bottom right;
  padding: 0 3px 3px 0;
  right: 0% !important;
  bottom: 0 !important;
}

/* ......................................... */
.select--is-disabled .select__control--is-disabled {
  background-color: var(--bs-gray-200) !important;
}

.tox-notifications-container {
  /* visibility: hidden !important; */
}

#knowledgeBase .nav-pills .show>.nav-link {
  color: #2c54cc !important;
}

.highlight {
  background-color: yellow;
}

.btn-red .label-icon {
  top: -5% !important;
}

.height-50 {
  height: 50px !important;
}

.fix-header-genrateDoc {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 47.4%;
  left: 46.88%;
}

.staffDocuments {
  position: fixed;
  width: 25.5%;
  top: 23%;
  /* transform: translateY(-20%); */
  transition: all 5s ease;
  /* box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; */
  padding: 0 1rem;
}

.credIndexImg {
  position: fixed;
  width: 25.33333333%;
  top: 25%;
  /* transform: translateY(-20%);
  transition: all 5s ease;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  padding: 0 1rem; */
}

.fix-genrateDoc-header .t-col-1 {
  width: 4%;
}

.fix-genrateDoc-header .t-col-2 {
  width: 12%;
}

.fix-genrateDoc-header .t-col-3 {
  width: 7%;
}

.fix-genrateDoc-header .t-col-4 {
  width: 8%;
}

#root+div {
  display: none;
}

#ResourceEstimation {
  max-height: 88vh !important;
}

#ImportLogs {
  max-height: 88vh !important;
  --bs-modal-width: 50vw !important;
}

.swal-confirm-button {
  float: right;
  margin-left: 10px;
}

.swal-cancel-button {
  float: left;
  margin-right: 10px;
}

.main-dropdown {
  width: 665px !important;
  padding: 5px 10px;
  flex-wrap: wrap;
}

.dropdown-head {
  overflow-x: hidden;
  margin-bottom: 10px;
}

.btn-modal-span {
  color: #bf1e30;
  font-size: 24px;
  line-height: 1px;
}

.btn-modal-close {
  border: none;
  background-color: transparent;
}

.action-width {
  width: 16.67%;
}

.id-width {
  width: 8.33%;
}

/* News Css Changes */
.border-radius {
  border-radius: 2px !important;
  font-size: 14px;
}

.border-right-none {
  border-right: none;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 39px;
}

.border-left-none {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-red {
  background-color: #bf1e30 !important;
  color: white !important;
  text-transform: uppercase;
}

.btn-purple {
  background-color: #5c0385 !important;
  color: white !important;
  text-transform: uppercase;
}

.btn-purple-light {
  background-color: #9a52bb !important;
  color: white !important;
  text-transform: uppercase;
}

.bg-purple {
  background-color: #5c0385 !important;
  color: white !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #5c0385 !important;
  border-radius: 2px;
}

.form-control:focus,
.form-select:focus {
  border: 1px solid #5c76f5 !important;
}

.side-tab {
  box-shadow: 2px 2px 5px #06000924;
  background-color: #f9f5fb !important;
}

.icon-size {
  font-size: 12px;
}

.icon-center {
  display: inline-block;
  transform: translateY(2px);
}

.row-hover:hover {
  background-color: #e8bff9 !important;
}

.row-hover:hover td {
  border: none;
}

tbody tr:nth-child(odd) {
  background-color: #f8f9fa;
}

/* sticky table */
.fix-header {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  /* top: 19.2%; */
  top: 120px;
  width: 91.2%;
  left: 4.4%;
}

.fix-header tr {
  display: flex;
}

/* fixed buttons */
.fixed-buttons {
  min-width: 50px;
  align-items: flex-end;
  position: fixed;
  right: 0;
  top: 19.2%;
  z-index: 50;
}

.fixed-buttons .fix-btn {
  min-width: 50px;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
}

.fix-btn span {
  display: inline-flex;
  transition: all 0.3s;
  width: 0;
  overflow: hidden;
}

.fix-btn:hover span {
  width: 55px;
}

.fix-btn:hover .add-family {
  width: 80px;
}

.fix-btn i {
  transform: translateX(5px);
}

.fix-btn:hover i {
  transform: translateX(0px);
}

.add-btn {
  height: 38px;
}

/* fixed side tab */
.fixed-side-tab {
  position: fixed;
  width: 212px;
  top: 19.2%;
}

.form-check-input {
  background-color: #adadada6 !important;
  border: none;
}

.form-check-input:checked {
  background-color: #5c0385 !important;
  border: 1px solid #5c0385;
}

.first_global:checked {
  background-color: #626ed4 !important;
  border: 1px solid #626ed4 !important;
}

.second_view:checked {
  background-color: #3498db !important;
  border: 1px solid #3498db !important;
}

.third_add:checked {
  background-color: #02a499 !important;
  border: 1px solid #02a499 !important;
}

.fourth_edit:checked {
  background-color: rgb(248, 180, 37) !important;
  border: 1px solid rgb(248, 180, 37) !important;
}

.fifth_delete:checked {
  background-color: #3498db !important;
  border: 1px solid #3498db !important;
}

.fifth_delete:checked {
  background-color: #5c0385 !important;
  border: 1px solid #5c0385 !important;
}

.red:checked {
  background-color: #adadada6 !important;
  border-color: #adadada6 !important;
}

.red {
  background-color: #adadada6 !important;
  border-color: #adadada6 !important;
}

/* .yellow:checked {
  background-color: #e4b7f9 !important;
  border-color: #e4b7f9 !important;
}
.yellow {
  background-color: #e4b7f9 !important;
  border-color: #e4b7f9 !important;
}
.ligth-green:checked {
  background-color: #bd36fa !important;
  border-color: #bd36fa !important;
}
.ligth-green {
  background-color: #bd36fa !important;
  border-color: #bd36fa !important;
} */

/* .green:checked {
  background-color: #5c0385 !important;
  border-color: #5c0385 !important;
}
.green {
  background-color: #5c0385 !important;
  border-color: #5c0385 !important;
} */
.form-switch .form-check-input {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important; */
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-1 -0.6 7 7%27%3e%3crect width=%276%27 height=%276%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.form-check-input {
  border-radius: 4px !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #5c0385d6 !important;
  border-radius: 2px;
}

.select__control,
input,
.input,
.form-select {
  border-radius: 2rem !important;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03) !important;
  /* border-color: #632182 !important; */
}

.bd-accordian-btn:not(.collapsed) {
  background-color: #dfffdf !important;
}

.text-black {
  color: black;
}

.btn-modal-span {
  color: #bf1e30;
  font-size: 24px;
  line-height: 1px;
}

.btn-modal-close {
  border: none;
  background-color: transparent;
}

.info-icon {
  color: rgb(200, 200, 200);
  transform: translateY(1px);
  font-size: 12px;
}

.id-width {
  width: 8.33%;
}

.table {
  vertical-align: middle;
}

.ck.ck-editor__main>.ck-editor__editable {
  height: 180px !important;
}

.contract-editor .ck.ck-editor__main>.ck-editor__editable {
  height: 700px !important;
}

.notes .ck.ck-editor__main>.ck-editor__editable {
  height: 100px !important;
}

.profileImg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.bd-span-input .input-span {
  padding: 0.47rem 0.75rem;
  border-radius: 2px;
  font-size: 13px;
  border: 1px solid var(--bs-input-border-color);
  background-color: var(--bs-gray-200);
  color: var(--bs-gray-700);
  min-height: 39px;
}

.custom-file-upload {
  background-color: #f3f6f9;
  cursor: pointer;
  height: 45px;
  font-size: 16px;
}

.pr-row {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
}

.pl-row {
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.display-none {
  display: none;
}

.select__menu {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  /* position: absolute !important; */
  z-index: 9999 !important;
}

#bookings .table> :not(caption)>*>* {
  padding: 0rem 1rem !important;
}

#bookings .t-column-1 {
  width: 4.5%;
}

#bookings .t-column-2 {
  width: 6%;
}

#bookings .t-column-3 {
  width: 12.5% !important;
}

#bookings .t-column-4 {
  width: 15% !important;
}

#bookings .t-column-5 {
  width: 11% !important;
}

#bookings .t-column-6 {
  width: 12% !important;
}

#bookings .t-column-7 {
  width: 9% !important;
}

#bookings .t-column-8 {
  width: 11% !important;
}

#bookings .t-column-9 {
  width: 10% !important;
}

#bookings .t-column-10 {
  width: 9% !important;
}

.copyBtn-i:hover {
  color: white !important;
}

/* #bookings .t-column-11 {
  width: 5% !important;
} */
/* .btn-label {
  padding-left: 44px;
  position: relative;
}
.btn-label .label-icon {
  align-items: center;
  background-color: #ffffff1a;
  border-right: 1px solid #ffffff1a;
  display: flex;
  font-size: 16px;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
} */
.calender-select {
  position: absolute;
  left: 13.5%;
  width: 45%;
}

#fc-dom-1 {
  position: absolute;
  /* left: 50.5%; */
  right: 13%;
  top: 0%;
  z-index: 99;
}
#Fav-lead-table{
  height: 235px;
  overflow: auto;
  scrollbar-width: thin;
}
#Fav-lead-table .t-col-1 {
  width: 1% !important;
}

#Fav-lead-table .t-col-2 {
  width: 1% !important;
  text-align: center;
}

#Fav-lead-table .t-col-3 {
  width: 14% !important;
}

#Fav-lead-table .t-col-4 {
  width: 2% !important;
}

.btn-save {
  background-color: #5c0385d6 !important;
  color: white !important;
  text-transform: uppercase;
}

.form-control:focus,
.form-select:focus {
  border: 1px solid #632182 !important;
}

.text-star {
  color: #f2c14b !important;
}

.select__control--is-focused,
.ck-focused {
  border-color: #5c0385d6 !important;
}

#settings-sales-Lead .fixed-header {
  width: 74.7% !important;
}

#settings-sales-Lead .t-col-1 {
  width: 7%;
}

#settings-sales-Lead .t-col-2 {
  width: 83%;
}

#settings-sales-Lead .t-col-3 {
  width: 10%;
}

.swal-confirm-popup-genral-setting {
  position: fixed;
  top: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #06000924;
}

.border-radius-swal-22 {
  border-radius: 1.5%;
}

.swal22-title {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1.625em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}

.swal-confirm-popup-genral-setting button {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em;
}

@media screen and (min-width: 1600px) {
  .verti-timeline-xxxl {
    margin-left: 8% !important;
  }
}

@media screen and (max-width: 600px) {
  .show-mobile {
    display: block !important;
  }
}

@media screen and (min-width: 1900px) {
  .fixed-buttons {
    top: 12.2%;
  }

  .fixed-setting-header-credential {
    top: 11.2%;
  }

  .fixed-side-tab {
    position: fixed;
    width: 15.7%;
    top: 12.2%;
  }
}
.scrollbar-width-thin{
  scrollbar-width: thin !important;
}

.color-grey {
  color: #495057;
}

.text-brown {
  color: #e46866;
}
.single_comp{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.nav-pills .nav-link.bg-danger-active {
  background-color: #f46a6a !important;
}
.text-purple{
  color: #53176c;
}