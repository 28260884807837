/* Swipe Issues  */
.swipe_id {
    width: 2% !important;
    text-align: center;
}

.swipe_name {
    width: 40% !important;
}

.swipe_status {
    width: 2% !important;
    text-align: center;
}

.swipe_date{
    width: 6% !important;
    
}
.swipe_action {
    width: 4% !important;
    text-align: center;
}

/* Issues Columns  */
.issues_id {
    width: 5% !important;
    text-align: center;
}

.issues_name {
    width: 40% !important;
}

.issues_status {
    width: 8% !important;
    text-align: center;
}

.issues_action {
    width: 4% !important;
    text-align: center;
}

.custom-swipe {
    border-radius: 50px;
    height: 37px;
    margin-top: 1px;
}
.fs-14{
    font-size: 14px !important;
}