#fc-dom-1 {
  position: absolute;
  /* left: 50.5%; */
  right: 50% !important;
  top: 0%;
  z-index: 99;
  }

.viewPackage-box {
  /* max-height: 400px !important; */
  height: 500px !important;
  overflow-y: auto;
  scrollbar-width: thin;
}

.bg-red1 {
  background-color: #c31e1e;
}

.Custom_package_box {
  max-height: 700px !important;
  overflow-y: auto;
  scrollbar-width: thin;
}

/* Edit Project -> Credentials Table */
.cred_name {
  width: 25% !important;
}

.website_url {
  width: 25% !important;
}

.user_id {
  width: 10% !important;
}

.cred_password {
  width: 10% !important;
}

.cred_comment {
  width: 15% !important;
}

.cred_action {
  width: 10% !important;
}

/* Edit Credential  */
.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: -13px;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.DMcopyMsg {
  top: 50%;
  left: 15%;
  font-weight: 600;
  /* background-color: #af27ee !important; */
  background-color: rgb(90, 57, 121) !important;
  color: white !important;
  padding: 3px 10px;
  border-radius: 5px;
}

/* Edit Projects -> Task  */
.dm_task_id {
  width: 5% !important;
}

.dm_assign_name_box {
  width: 20% !important;
}

.dm_task_name_box {
  width: 20% !important;
}

.dm_quantity_box {
  width: 6% !important;
}

.dm_month_box {
  width: 8% !important;
}

.dm_status_box {
  width: 10% !important;
}

.dm-planing-h {
  height: 35px;
}

.dm-planning-step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.dm-planning-step i {
  font-size: 18px;
}

.dm-planning li::after {
  top: 20px;
}

.dm-planning .caption {
  padding: 6px 16px;
}

.dm-sidebar-container {
  width: 25% !important;
  position: fixed !important;
}

.dm-sidebar-container .nav-link {
  width: 26% !important;
}

.overview-table {
  width: 93% !important;
  margin-left: 7%;
}

.SEO-color {
  color: #0066cc;
}

.PPC-color {
  color: #ff6600;
}

.SMM-color {
  color: #34a853;
}

.EM-color {
  color: #e91e63;
}

.plan-qty {
  width: 8%;
}

.today-table {
  width: 89%;
}

.targ-qty {
  background-color: #ffd9d9 !important;
}

.bd-custome-editor .ql-snow {
  border-radius: 9px;
  margin-bottom: 10px;
  border: 1px solid #ccc !important;
}

.bd-custome-editor .ql-container {
  max-height: 335px !important;
  overflow-y: hidden !important;
}
.act-qty {
  display: flex;
  width: 21px;
  height: 17px;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border-radius: 64px;
  font-size: 11px;
}
.bg-seo {
  background-color: #47a3ff !important;
}
.bg-smm {
  background-color: #e268f7 !important;
}
.bg-ppc {
  background-color: #12c1b1 !important;
}
.bg-em {
  background-color: #b0b0ae !important;
}
.sprint-buttns {
  margin-top: -58px;
  margin-bottom: 20px;
}
.sprint-box {
  padding: 11px 10px;
  background-color: #f3dbff;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.bg-pause-project {
  background-color: #e0edff !important;
}
.plan-qtys {
  width: 10%;
}

.side-digital-tab {
  width: 13% !important;
}
.side-digital-list {
  width: 87% !important;
}

.side-digital-tab .fixed-side-tab{
  width: 12%;
}
.log-table{
  height: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.custom-initial td{
  position: relative !important;
}
.copy-input{
  position: absolute;
  right: 11px;
  top: 10px;
  color: #762b98;
  cursor: pointer;
  transition: 'opacity 0.3s';
  opacity: 0;
}
.input-container:hover .copy-input {
  opacity: 1;
}
.copy-tooltip{
  position: absolute;
  top: 7px;
  right: 9px;
  background: #762b98;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 10px;
  transition: opacity 0.3s;
}
.custom_info_box {
  padding-bottom: 5px;
  margin-left: 15px;
  margin-right: 5px;
}