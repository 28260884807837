/* fixed header styling */
.fix-lead-header .t-col-1 {
  width: 4%;
}

.fix-lead-header .t-col-2 {
  width: 7%;
}

.fix-lead-header .t-col-3 {
  width: 19%;
  word-break: break-all;
}

.fix-lead-header .t-col-4 {
  width: 14%;
}

.fix-lead-header .t-col-5 {
  width: 16%;
  word-break: break-all;
}

.fix-lead-header .t-col-6 {
  width: 10%;
}

.fix-lead-header .t-col-7 {
  width: 6.5%;
}

.fix-lead-header .t-col-8 {
  width: 11%;
}

.fix-lead-header .t-col-9 {
  width: 12.5%;
}

/* popup form */
.popup-form {
  background-color: white;
  width: 100%;
  position: absolute;
  top: -24px;
  right: 0%;
  box-shadow: 2px 2px 50px #8080807e;
  z-index: 99;
}
.note-filter .popup-form {
  /* top: -350%; */
  position: relative;
}
/* .react-resizable {
padding: 0;
} */
 .notes-filter-pop  {
  box-shadow: none !important;

 }

.note-filter .pop-icon {
  /* right: 28.5%; */
  display: none;
}
.contract-form-filter{
  width: 80%;
  top: -18px;
  right: 0%;
}

.pop-icon {
  color: white;
  position: absolute;
  top: -7.5%;
  right: 18.2%;
}
.contract-form-filter .pop-icon{
  top: -10%;
  right: 26.2%;
}
.text-box-dash {
  text-align: center;
  padding: 60px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}
.bd-hot {
  background-color: #f9e7e3 !important;
  /* border: transparent; */
}
.bd-hot-follow {
  background-color: #f6c4b9 !important;
  /* border: transparent; */
}
.bd-warm {
  background-color: #f9e7e3 !important;
  /* border: transparent; */
}

.bd-cold {
  background-color: #ebf7ff !important;
  /* border: transparent; */
}
.edit-descrip .ck.ck-editor__main > .ck-editor__editable,
.lead-desc .ck.ck-editor__main > .ck-editor__editable {
  height: 400px !important;
}

.activity-log-btn {
  background-color: #f9f5fb !important;
  padding: 8px;
  border-radius: 5px !important;
  font-size: 11px !important;
}
.text-purple{
  color: #53176c;
}
.bd-company-name{
  font-weight: 700;
  text-transform: uppercase;
  color: #a73592
}
.assign-1{
  display: none !important;
}

.document-content p{
  text-align: justify;
}

/* // Edit lead info  */
#edit-lead .company-info{
  /* background-color: #752997; */
  /* background-color: #dba6f3; */
  background-color: #c89fdb;
  color: black;
  /* padding-top: 12px;
  padding-bottom: 10px; */
}

#edit-lead .company-info h5{
  font-weight: normal;
}
.word-wrap p{
  word-break: break-all;
}