/* ztpl  */

.fix-ztpl-header .t-col-1 {
    width: 8%;
}
.fix-ztpl-header .t-col-2 {
    width: 14%;
}
.fix-ztpl-header .t-col-3 {
    width: 20%;
}
.fix-ztpl-header .t-col-4 {
    width: 28%;
}
.fix-ztpl-header .t-col-5 {
    width: 14%;
}
.fix-ztpl-header .t-col-6 {
    width: 16%;
}