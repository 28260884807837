/* .bg-calender-content {
  background-color: #eaeaf6 !important;
  color: #556ee6;
} */
.bg-calender-content {
  background-color: #464775 !important;
  color: white !important;
}
.popup-mini {
  display: none;
}
.hover-container:hover .popup-mini {
  display: block !important;
  z-index: 9 !important;
}
.hover-container,
.fc-daygrid-day-frame {
  position: relative;
  z-index: 1 !important;
}

.popup-mini {
  position: absolute !important;
  top: 100% !important;
  left: 20%;
  padding: 1rem;
  background-color: white;
}
.popup-mini-title {
  text-align: left;
}
.meeting-container {
  position: absolute;
  left: 108%;
  top: -50% !important;
  background-color: white;
  transform: translate(0%, 15%);
}
.btn-meeting {
  background-color: #330148 !important;
  /* background-color: #464775 !important; */
  color: white !important;
}
.text-link {
  color: #6264a7 !important;
}
.btn-meeting:hover {
  color: white;
}
.meeting-popup {
  position: relative;
  border-radius: 10px;
}
.meeting-popup::before {
  content: "";
  position: absolute;
  top: 20%;
  left: -20px; /* Adjust the position of the triangle */
  transform: translateY(-50%);
  border-width: 10px; /* Adjust the size of the triangle */
  border-style: solid;
  border-color: transparent white transparent transparent; /* Set the color of the triangle */
}

/* Modal */
.meetingModal {
  height: 90vh !important;
  z-index: 99999999 !important;
  --bs-modal-width: 70vw !important;
}
#modalToggle {
  position: absolute;
  background-color: #ffff;
  border-radius: 50%;
  right: -15px !important;
  top: -15px !important;
  z-index: 999 !important;
}
#modalToggle i {
  font-size: 15px !important;
}
