
/* .fix-header{
    width: 92.2%;
} */

.fix-budget-header{
	position: fixed;
	background-color: #981f77;
	color: white;
	z-index: 40;
	/* top: 19.2%; */
	top: 120px;
	width: 91.2%;
	left: 4.4%;
}
.fix-introducer-header .t-col-2{
    width: 4%;
}
.fix-introducer-header .t-col-3 {
    width: 4%;
}

.fix-introducer-header .t-col-4 {
    width: 22%;
}
.fix-introducer-header .t-col-5 {
    width: 22%;
}
.fix-introducer-header .t-col-6 {
    width: 5%;
}
.fix-introducer-header .t-col-7 {
    width: 5%;
}


.budget_status{
    background-color: #5c038517;
    /* width: 22%; */
}
.budget_status:hover{
    background-color: #5c038533;
}