#blogContent figure {
  max-width: 100% !important;
}
#blogContent table th,
#blogContent table td {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border: 1px solid #ccc;
  padding-left: 8px;
  background-color: white !important;
}

.custom-table {
  /* Add custom styles here */
  font-size: 14px; /* Example custom style */
}

.custom-table .table-bordered {
  border-color: #ccc; /* Blue border color */
}

.custom-table .table-bordered th,
.custom-table .table-bordered td {
  border-color: #ccc; /* Blue border color */
}
.knowledgebase_view{
  p{
    color : #0e0e0e !important;
  }

  /* .tox .tox-menu.tox-collection.tox-collection--list.tox-selected-menu, .tox .tox-collection--toolbar.tox-collection--toolbar-lg.tox-selected-menu, .tox .tox-swatches-menu.tox-selected-menu{
    z-index: 10000;
  } */

}