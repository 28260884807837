.fix-fixed-support-header{
	position: fixed;
	background-color: #981f77 !important;
	color: white;
	z-index: 40;
	/* top: 19.2%; */
	top: 120px;
	width: 92.1%;
	left: 4%;
}

.s_select{
    width: 2.5% !important;
}
.fix-fixed-support-header .s_select{
	/* width: 4% !important; */
	/* width: 3.5% !important; */
	width: 3.1% !important;
}

.s_id{
    width: 3% !important;
}
.fix-fixed-support-header .s_id{
	/* width: 5.1% !important; */
	/* width: 4.8% !important; */
	width: 4.0% !important;
}

.assigneds_name_box {
	width: 4.5% !important;
}
.assigneds_name_box img{
	width: 50% !important;
}
.fix-fixed-support-header .assigneds_name_box{
	/* width: 6.4% !important; */
	width: 5.6% !important;
}

.stype_name_box{
	width: 4% !important;
}
.stype_name_box img{
	width: 45% !important;
}
.type_img_suggestion img{
	width: 60% !important;
}
.fix-fixed-support-header .stype_name_box{
	/* width: 7.6% !important; */
	/* width: 7.0% !important; */
	width: 4.8% !important;
}

.scustomer_box{
	width: 11% !important;
}
.fix-fixed-support-header .scustomer_box{
	/* width: 16.4% !important; */
	width: 15.1% !important;
}

.stask_name_box{
	width: 26% !important;
}
.fix-fixed-support-header .stask_name_box{
	/* width: 26.6% !important; */
	width: 32.6% !important;
}

.sstatus_box{
	width: 7.8% !important;
	text-align: center;
}
.fix-fixed-support-header .sstatus_box{
	/* width: 9% !important; */
	width: 9.0251% !important;
}

.prioritys_col_box{
	width: 5% !important;
}
.fix-fixed-support-header .prioritys_col_box{
	width: 6.5% !important;
}

.screated_at_col-box{
	width: 6.1% !important;
}
.fix-fixed-support-header .screated_at_col-box{
	width: 7.5% !important;
}

.slast_reply_col-box{
	width: 5.2% !important;
}
.fix-fixed-support-header .slast_reply_col-box{
	width: 7.2% !important;
}


.msupport_action {
	width: 1.2% !important;
}
.fix-fixed-support-header .msupport_action{
	width: 7% !important;
}

/* .fix-fixed-support-header .col-t-1{
	width: 3% !important;
	max-width: 3% !important;
} */
/* .fix-fixed-support-header .col-t-2{
	width: 4% !important;
	max-width: 4% !important;
} */
 
/* .fix-fixed-support-header .col-t-3{
	width: 5.6% !important;
	max-width: 6.5% !important;
} */

/* .fix-fixed-support-header .col-t-4{
	width: 7.9% !important;
	max-width: 8.4% !important;
} */
/* .fix-fixed-support-header .col-t-5{
	width: 19.2% !important;
	max-width: 19.7% !important;
} */

/* .fix-fixed-support-header .col-t-6{
	width: 21.67% !important;
	max-width: 21.67%  !important;

} */
/* .fix-fixed-support-header .col-t-7{
	width: 10.1% !important;
	max-width: 10.1% !important;
} */
/* .fix-fixed-support-header .col-t-8{
	width: 7.1% !important;
	max-width: 7.1% !important;
} */
/* .fix-fixed-support-header .col-t-9{
	width: 8.1% !important;
	max-width: 8.1% !important;
} */
/* .fix-fixed-support-header .col-t-10{
	width: 8.1% !important;
	max-width: 8.1% !important;
} */
/* .fix-fixed-support-header .col-t-11{
	width: 8.1% !important;
	max-width: 8.1% !important;
} */

.fix-support-table-body{
	/* color: black; */
    background-color: #fff;
}
#supportNew-filter .popup-form {
	background-color: white;
	width: 100%;
	position: absolute;
	top: -22px;
	right: 1.5%;
	box-shadow: 2px 2px 50px #8080807e;
	z-index: 99;
}
.highPriority{
	background-color: #ffe8e8 !important;
  }

  /* ==================================================================== */
  .connected-dot {
    position: relative;
  }

  /* .connected-dot::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #ccc;
    z-index: -1;
    transform: translateX(-50%);
  } */

  .event-list:first-child .connected-dot::before {
    top: 50%;
  }

  .event-list:last-child .connected-dot::before {
    height: 50%;
  }

  .msgUserImg {
	width: 40px !important;
	height: 40px !important;
	border-radius: 50px;
	border: 3px solid lightgray;
	position: relative;
	right: 20%;
}
  
  .msgUserImg {
	width: 40px !important;
	height: 40px !important;
	border-radius: 50px;
	border: 3px solid lightgray;
	position: relative;
	right: 20%;
}


.ticket-history-box{
	max-height: 600px !important;
	overflow-y: auto;
	scrollbar-width:thin;
  }
  
  .ticket-history-box::-webkit-scrollbar {
	width: 6px;
  }
  
  .ticket-history-box::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
  }
  
  .ticket-history-box::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
  }
  
  .ticket-history-box::-webkit-scrollbar-thumb:hover {
	background: #555;
  }

  /* ========================================================================= */
  .support-logsmodal-header{
	align-items: center;
	border-bottom: 1px solid rgb(212, 208, 208);
	border-top-left-radius: var(--bs-modal-inner-border-radius);
	border-top-right-radius: var(--bs-modal-inner-border-radius);
	display: flex;
	flex-shrink: 0;
	justify-content: space-between;
	padding: 10px 5px;
  }

  .support-logsmodal-body {
	flex: 1 1 auto;
	padding: 15px 5px 10px ;
	position: relative;
}

/* //================================================================================== */

.ticket-close-box {
	max-height: 400px !important;
	overflow-y: auto;
	scrollbar-width: thin;
}
/* //================================================================= */
  
  .customShortIcon{
    position: relative;
  }
  .customShortIcon span{
   position: absolute;
   top: 50%;
   right:10px;
   transform: translateY(-50%);
  }


/* //============================	Media Query ===================================== */

@media screen and (min-width: 1900px) and (max-width: 2000px) {
	.customShortIcon span {
		position: absolute;
		top: 50%;
		right: 2px;
		transform: translateY(-50%);
	}
	.fix-fixed-support-header .customShortIcon span {
		top: 50% !important;
	}

	/* Select Column */
	.s_select {
		width: 4.5% !important;
	}
	.fix-fixed-support-header .s_select {
		width: 4.5% !important;
	}

	/* ID Column */
	.s_id {
		text-align: center !important;
		width: 6.5% !important;
	}
	.fix-fixed-support-header .s_id {
		width: 6.3% !important;
	}

	/* Assignee Column */
	.assigneds_name_box {
		text-align: center !important;
		width: 8.5% !important;
	}
	.assigneds_name_box img {
		width: 50% !important;
	}
	.fix-fixed-support-header .assigneds_name_box {
		width: 7.6% !important;
	}

	/* Type Column */
	.stype_name_box {
		width: 8% !important;
		text-align: center;
	}
	.stype_name_box img {
		width: 50% !important;
	}
	.fix-fixed-support-header .stype_name_box {
		width: 7% !important;
	}

	/* Customer Column */
	.scustomer_box {
		width: 12.5% !important;
	}
	.fix-fixed-support-header .scustomer_box {
		width: 12.65% !important;
	}

	/* Task Name Column */
	.stask_name_box {
		width: 24% !important;
	}
	.fix-fixed-support-header .stask_name_box {
		/* width: 22.5% !important; */
		width: 22.52% !important;
	}

	/* Status Column */
	.sstatus_box {
		width: 14% !important;
		text-align: center !important;
	}
	.fix-fixed-support-header .sstatus_box {
		width: 13.5% !important;
	}

	/* Priority Column */
	.prioritys_col_box {
		width: 8% !important;
		text-align: center;
	}
	.fix-fixed-support-header .prioritys_col_box {
		width: 8.0% !important;
	}

	/* Created At Column */
	.screated_at_col-box {
		width: 7% !important;
		text-align: start;
	}
	.fix-fixed-support-header .screated_at_col-box {
		width: 7% !important;
	}

	/* Last Reply Column */
	.slast_reply_col-box {
		width: 7% !important;
		text-align: start !important;
	}
	.fix-fixed-support-header .slast_reply_col-box {
		width: 6.97% !important;
	}

	/* Action Column */
	.msupport_action {
		width: 7% !important;
	}
	.fix-fixed-support-header .msupport_action {
		width: 4% !important;
	}

	/* Support Status Cards */
	.support-status {
		width: 18% !important;
	}

	/* Filter Position */
	#support-filter {
		position: fixed;
		top: 24%;
		right: 3% !important;
		z-index: 1055;
		background-color: #fff;
		min-width: 94% !important;
	}
}


@media screen and (min-width : 1400px ) and (max-width : 1500px) {
	/* Table Header  */
	.customShortIcon span {
		position: absolute;
		top: 50%;
		right: 2px;
		transform: translateY(-50%);
	}
	.fix-fixed-support-header  .customShortIcon span{
		top: 68% !important;
	}
	.s_select{
		width: 3.5% !important;
	}
	.fix-fixed-support-header .s_select {
		width: 3.8% !important;
	}


	.s_id{
		text-align: start !important;
		width: 5% !important;
	}
	.fix-fixed-support-header .s_id {
		/* width: 3.8% !important; */
		width: 4.6% !important;
	}


	.assigneds_name_box {
		text-align: start !important;
		width: 7.5% !important;
	}
	.assigneds_name_box img{
		width: 50% !important;
	}
	.fix-fixed-support-header .assigneds_name_box {
		/* width: 6.4% !important; */
		/* width: 6.4% !important */
		width: 6.9% !important
	}


	.stype_name_box{
		width: 6.5% !important;
	}
	.stype_name_box img {
		width: 50% !important;
	}
	.type_img_suggestion img{
		width: 60% !important;
	}
	/* .type_img{
		width: 50% !important;
	}
	.type_img_suggestion{
		width: 60% !important;
	} */
	
	.fix-fixed-support-header .stype_name_box {
		/* width: 7.6% !important; */
		/* width: 7.1% !important; */
		width: 6.7% !important;
	}

	.scustomer_box{
		width: 11% !important;
	}
	.fix-fixed-support-header .scustomer_box {
		width: 10.4% !important;
	}


	.stask_name_box {
		width: 26% !important;
	}
	.fix-fixed-support-header .stask_name_box {
		/* width: 19.6% !important; */
		width: 23.75% !important;
	}
	

	.sstatus_box {
		width: 14% !important;
		text-align: center !important
	}
	.fix-fixed-support-header .sstatus_box {
		/* width: 9% !important; */
		/* width: 11% !important; */
		width: 12.4% !important;
	}


	.prioritys_col_box {
		width: 8% !important;
		text-align: start;
	}
	.fix-fixed-support-header .prioritys_col_box {
		width: 8.76%!important
	}

	.screated_at_col-box {
		width: 9.5% !important;
		text-align: start;
	}
	.fix-fixed-support-header .screated_at_col-box {
		width: 8.9% !important;
	}

	.slast_reply_col-box {
		width: 9.5% !important;
		text-align: start !important;
	}
	.fix-fixed-support-header .slast_reply_col-box {
		width: 8.5% !important;
	}

	.msupport_action {
		width: 1.2% !important;
	}
	.fix-fixed-support-header .msupport_action {
		width: 5.4% !important;
	}

	/* Cards  */
	.support-status{
		width: 18% !important;
	}

	#support-filter{
		position: fixed;
		top: 24%;
		right: 3% !important;
		z-index: 1055;
		background-color: #fff;
		min-width: 94% !important;
	  }
}

@media screen and (min-width : 1300px ) and (max-width : 1400px) {
	/* Table Header  */
	.customShortIcon span {
		position: absolute;
		top: 50%;
		right: 2px;
		transform: translateY(-50%);
	}
	.fix-fixed-support-header  .customShortIcon span{
		top: 68% !important;
	}
	.s_select{
		width: 3.5% !important;
	}
	.fix-fixed-support-header .s_select {
		width: 3% !important;
	}


	.s_id{
		text-align: start !important;
		width: 5% !important;
	}
	.fix-fixed-support-header .s_id {
		/* width: 3.8% !important; */
		width: 4.8% !important;
	}


	.assigneds_name_box {
		text-align: start !important;
		width: 7.5% !important;
	}
	.assigneds_name_box img{
		width: 50% !important;
	}
	.fix-fixed-support-header .assigneds_name_box {
		/* width: 6.4% !important; */
		width: 5.5% !important;
	}


	.stype_name_box{
		width: 6.5% !important;
	}
	.stype_name_box img {
		width: 50% !important;
	}
	.type_img_suggestion img{
		width: 60% !important;
	}
	/* .type_img{
		width: 50% !important;
	}
	.type_img_suggestion{
		width: 60% !important;
	} */
	
	.fix-fixed-support-header .stype_name_box {
		/* width: 7.6% !important; */
		/* width: 7.1% !important; */
		width: 5.5% !important;
	}

	.scustomer_box{
		width: 11% !important;
	}
	.fix-fixed-support-header .scustomer_box {
		/* width: 8.4% !important; */
		width: 9% !important;
	}


	.stask_name_box {
		width: 26% !important;
	}
	.fix-fixed-support-header .stask_name_box {
		/* width: 19.6% !important; */
		/* width: 19.8% !important; */
		width: 21.5% !important;
	}
	

	.sstatus_box {
		width: 13% !important;
		text-align: center !important
	}
	.fix-fixed-support-header .sstatus_box {
		/* width: 9% !important; */
		/* width: 11% !important; */
		width: 10.8% !important;
	}


	.prioritys_col_box {
		width: 8% !important;
		text-align: start;
	}
	.fix-fixed-support-header .prioritys_col_box {
		width: 7.0% !important;
	}

	.screated_at_col-box {
		width: 9.5% !important;
		text-align: start;
	}
	.fix-fixed-support-header .screated_at_col-box {
		width: 8.1% !important;
	}

	.slast_reply_col-box {
		width: 10.5% !important;
		text-align: start !important;
	}
	.fix-fixed-support-header .slast_reply_col-box {
		width: 8.4% !important;
	}
	/* Chrome Browser  */
	@media screen and (-webkit-min-device-pixel-ratio:0) {
		.slast_reply_col-box{
			width: 10.5% !important;

		}
	}
	.msupport_action {
		width: 1.2% !important;
	}
	.fix-fixed-support-header .msupport_action {
		width: 9% !important;
	}

	/* Cards  */
	.support-status{
		width: 18% !important;
	}

	#support-filter{
		position: fixed;
		top: 24%;
		right: 3% !important;
		z-index: 1055;
		background-color: #fff;
		min-width: 94% !important;
	  }
}

@media screen and (min-width : 1200px ) and (max-width : 1300px) {
	.customShortIcon span {
		position: absolute;
		top: 80%;
		right: 3px;
		transform: translateY(-50%);
	}
	/* .fix-fixed-support-header{
		width: 92.1;
	} */
	.s_select{
		width: 3.5% !important;
		/* width: 3.5vw !important; */
	}
	.fix-fixed-support-header .s_select {
		width: 4% !important;
	}


	.s_id{
		text-align: start !important;
		width: 6% !important;
	}
	.fix-fixed-support-header .s_id {
		/* width: 4.2% !important; */
		/* width: 4.9% !important; */
		width: 4.0% !important;
	}


	.assigneds_name_box {
		text-align: start !important;
		/* width: 7.5% !important; */
		width: 8% !important;
	}
	.assigneds_name_box img{
		width: 50% !important;
	}
	.fix-fixed-support-header .assigneds_name_box {
		/* width: 6.4% !important; */
		/* width: 5.5% !important; */
		/* width: 3.7% !important; */
		width: 6.3% !important;
	}


	.stype_name_box{
		width: 8.5% !important;
	}
	.stype_name_box img {
		width: 50% !important;
	}
	.type_img_suggestion img{
		width: 60% !important;
	}
	/* .type_img{
		width: 50% !important;
	}
	.type_img_suggestion{
		width: 60% !important;
	} */
	
	.fix-fixed-support-header .stype_name_box {
		/* width: 7.6% !important; */
		/* width: 7.1% !important; */
		/* width: 6.4% !important; */
		width: 6.2% !important;
	}

	.scustomer_box{
		width: 11% !important;
	}
	.fix-fixed-support-header .scustomer_box {
		/* width: 9% !important; */
		width: 9.4% !important;
	}


	.stask_name_box {
		width: 20% !important;
	}
	.fix-fixed-support-header .stask_name_box {
		/* width: 19.6% !important; */
		width: 17.6% !important;
	}
	

	.sstatus_box {
		width: 16% !important;
		text-align: center !important
	}
	.fix-fixed-support-header .sstatus_box {
		/* width: 9% !important; */
		/* width: 11% !important; */
		width: 12.5% !important;
	}


	.prioritys_col_box {
		width: 9% !important;
		text-align: start;
	}
	.fix-fixed-support-header .prioritys_col_box {
		width: 7.0% !important;
	}

	.screated_at_col-box {
		width: 10.1% !important;
		text-align: start;
	}
	.fix-fixed-support-header .screated_at_col-box {
		width: 8.5% !important;
	}

	.slast_reply_col-box {
		width: 8.2% !important;
		text-align: start !important;
	}
	.fix-fixed-support-header .slast_reply_col-box {
		width: 7.4% !important;
	}

	.msupport_action {
		width: 1.2% !important;
	}
	.fix-fixed-support-header .msupport_action {
		width: 10% !important;
	}

}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
    .customShortIcon span {
        position: absolute;
        top: 75%;
        right: 3px;
        transform: translateY(-50%);
    }

    .s_select {
        width: 4% !important;
    }
    .fix-fixed-support-header .s_select {
        width: 4.5% !important;
    }

    .s_id {
        text-align: start !important;
        width: 6.5% !important;
    }
    .fix-fixed-support-header .s_id {
        width: 5.2% !important;
    }

    .assigneds_name_box {
        text-align: start !important;
        width: 8.5% !important;
    }
    .assigneds_name_box img {
        width: 50% !important;
    }
    .fix-fixed-support-header .assigneds_name_box {
        /* width: 6.5% !important; */
        width: 6.2% !important;
    }

    .stype_name_box {
        width: 9% !important;
    }
    .stype_name_box img {
        width: 50% !important;
    }
    
    .fix-fixed-support-header .stype_name_box {
        width: 6.5% !important;
    }

    .scustomer_box {
        width: 11% !important;
    }
    .fix-fixed-support-header .scustomer_box {
        /* width: 9.5% !important; */
        width: 9.1% !important;
    }

    .stask_name_box {
        width: 21% !important;
    }
    .fix-fixed-support-header .stask_name_box {
        /* width: 18% !important; */
        width: 16.99% !important;
    }

    .sstatus_box {
        width: 15% !important;
        text-align: center !important;
    }
    .fix-fixed-support-header .sstatus_box {
        width: 12% !important;
    }

    .prioritys_col_box {
        width: 8% !important;
        text-align: start;
    }
    .fix-fixed-support-header .prioritys_col_box {
        width: 7.5% !important;
    }

    .screated_at_col-box {
        width: 9.5% !important;
        text-align: start;
    }
    .fix-fixed-support-header .screated_at_col-box {
        /* width: 8% !important; */
        width: 9% !important;
    }

    .slast_reply_col-box {
        width: 8% !important;
        text-align: start !important;
    }
    .fix-fixed-support-header .slast_reply_col-box {
        /* width: 7.5% !important; */
        width: 8.5% !important;
    }

    .msupport_action {
        width: 2% !important;
    }
    .fix-fixed-support-header .msupport_action {
        /* width: 9% !important; */
        width: 11% !important;
    }
}


@media screen and (min-width : 1100px) and (max-width : 1300px) {
	/* Cards  */
	.support-status{
		width: 23% !important;
	}
	#support-header{
		width: 100% !important;
	}
	#support-header .support-status-part{
		width: 80% !important;
	}
	#support-header .support-action-part{
		width: 20% !important;
	}
}


