.location-img {
    object-fit: cover;
    width: 30px !important;
    height: 20px !important;
}

.fixed-general-task-header {
    position: fixed;
    background-color: #981f77 !important;
    color: white;
    z-index: 40;
    /* top: 19.2%; */
    top: 120px;
    width: 91.9%;
    left: 4.1%;
}



/* .fixed-general-task-header .g_id{
    width: 6% !important;
} */

.g_id {
    width: 4% !important;
}

.fixed-general-task-header .g_id {
    width: 4% !important;
}

.fixed-general-task-header .assignedg_name_box {
    width: 7.7% !important;
}

.assignedg_name_box {
    width: 7% !important;
}


.fixed-general-task-header .glocation_name_box {
    width: 7.1% !important;
}

.glocation_name_box {
    width: 7.5% !important;
}


.fixed-general-task-header .gtask_name_box {
    /* width: 30.5% !important; */
    /* width: 31.35% !important; */
    width: 31.27% !important
}

.gtask_name_box {
    width: 30% !important;
}


.fixed-general-task-header .grelatedto {
    /* width: 10.5% !important; */
    width: 10.04% !important;
}

.grelatedto {
    width: 10% !important;
}

.fixed-general-task-header .gdue-date {
    /* width: 10% !important; */
    width: 8.79% !important;
}

.gdue-date {
    /* width: 7% !important; */
    width: 8.2% !important;
}

.fixed-general-task-header .gprioritys_col_box {
    width: 9.92% !important;
}

.gprioritys_col_box {
    width: 8% !important;
}

.fixed-general-task-header .gstatus_box {
    width: 10% !important;
}

.gstatus_box {
    width: 10% !important;
}

.fixed-general-task-header .gspent_hours-box {
    width: 7% !important;
}

.gspent_hours-box {
    width: 7.5% !important
}

.fixed-general-task-header .gtask_action {
    width: 8% !important;
}

.gtask_action {
    width: 7% !important;
}

.time-taken-table {
    height: 350px !important;
    overflow-y: scroll;
    scrollbar-width: thin;
}

/* .header{
    position: sticky;
    top: 0;
} */
.customShortIcon {
    position: relative;
}

.customShortIcon .customIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.custom-text-center {
    text-align: center;
}

.assignee-img {
    /* width: 30% !important;
    border-radius: 100% !important; */
    /* width: auto !important; */
    width: 25px !important;
    border-radius: 28px;
    object-fit: cover;
    height: 25px !important;
}

.sf-fileds {
    width: 70% !important;
}

.sf-actions {
    width: 30% !important;
}

.sf-location-box {
    width: 10% !important;
}

.custom_subtask_box{
    min-height: 600px;
    height: 500px;
    overflow-y:visible;
    scrollbar-width:auto !important;
    overflow-x: hidden;
}

/* ====================================   Media Query     ================================ */
@media screen and (min-width : 1900px) and (max-width : 2000px) {
    .customShortIcon {
        position: relative;
    }

    .customShortIcon .customIcon {
        position: absolute;
        top: 65%;
        right: 0px;
        transform: translateY(-50%);
    }

    .custom-text-center {
        text-align: center;
    }

    .g_id {
        text-align: start;
        width: 5% !important;
    }

    .fixed-general-task-header .g_id {
        width: 5% !important;
    }

    .fixed-general-task-header .assignedg_name_box {
        width: 7.5% !important;
    }

    .assignedg_name_box {
        text-align: start;
        width: 8% !important;
    }

    .fixed-general-task-header .gtask_name_box {
        /* width: 30.5% !important; */
        /* width: 31.35% !important; */
        /* width: 26.4% !important; */
        /* width: 30.28% !important; */
        width: 29.88% !important;
    }

    .fixed-general-task-header .grelatedto {
        /* width: 10.5% !important; */
        width: 10.04% !important;
    }

    .glocation_name_box {
        width: 7.9% !important;
        text-align: start;
    }

    .fixed-general-task-header .glocation_name_box {
        width: 7.8% !important;
        text-align: start;
    }

    .fixed-general-task-header .gdue-date {
        /* width: 10% !important; */
        width: 7.84% !important;
    }

    .fixed-general-task-header .gprioritys_col_box {
        width: 8.5% !important;
    }

    .fixed-general-task-header .gtask_action {
        width: 4% !important;
    }

    .fixed-general-task-header .col-t-8 {
        /* width: 11% !important; */
        width: 12.42% !important;
    }

    .gstatus_box {
        width: 13% !important;
    }

    .fixed-general-task-header .gspent_hours-box {
        width: 7.05% !important;
    }

    #generalTask .fix-table-header .gprioritys_col_box {
        text-align: start;
        width: 7% !important;
    }

    #generalTask .fix-table-header .gspent_hours-box {

        text-align: start;
    }

}


@media screen and (min-width : 1400px) and (max-width : 1500px) {
    .customShortIcon {
        position: relative;
    }

    .customShortIcon .customIcon {
        position: absolute;
        top: 65%;
        right: 0px;
        transform: translateY(-50%);
    }


    .fixed-general-task-header .col-t-8 {
        width: 11% !important;
    }

    .gstatus_box {
        width: 13% !important;
        text-align: start !important;
    }

    .custom-text-center {
        text-align: center;
    }


    .g_id {
        text-align: start;
        width: 5% !important;
    }

    .fixed-general-task-header .g_id {
        width: 5% !important;
    }

    .fixed-general-task-header .assignedg_name_box {
        width: 7% !important;
    }

    .assignedg_name_box {
        text-align: start;
        width: 8% !important;
    }

    .fixed-general-task-header .glocation_name_box {
        width: 7.1% !important;
    }

    .fixed-general-task-header .gtask_name_box {
        /* width: 30.5% !important; */
        /* width: 31.35% !important; */
        /* width: 26.4% !important; */
        width: 26.0% !important;
    }

    .fixed-general-task-header .grelatedto {
        /* width: 10.5% !important; */
        width: 9.04% !important;
    }

    .glocation_name_box {
        width: 7.9% !important;
        text-align: start;
    }

    .fixed-general-task-header .glocation_name_box {
        width: 7.5% !important;
        text-align: start;
    }

    .fixed-general-task-header .gdue-date {
        /* width: 10% !important; */
        width: 8.2% !important;
        text-align: start !important;
    }
    .gdue-date{
        width: 8.2 !important;
        text-align: start !important;
    }
    .fixed-general-task-header .gprioritys_col_box {
        width: 7.8% !important;
    }

    .fixed-general-task-header .gtask_action {
        width: 9.5% !important;
    }

    #generalTask .fix-table-header .gprioritys_col_box {
        text-align: start;
        width: 7% !important;
    }

    #generalTask .fix-table-header .gspent_hours-box {
        text-align: start;
    }

    #generalTask .fix-table-header .gspent_hours-box {
        text-align: start;
    }
}

@media screen and (min-width : 1300px) and (max-width : 1400px) {
    .customShortIcon {
        position: relative;
    }

    #generalTask .customShortIcon .customIcon {
        position: absolute;
        top: 65%;
        right: 0px;
        transform: translateY(-50%);
    }


    .fixed-general-task-header .col-t-8 {
        width: 11% !important;
    }

    .gstatus_box {
        width: 13% !important;
    }

    .custom-text-center {
        text-align: center;
    }


    .g_id {
        text-align: start;
        width: 5% !important;
    }

    .fixed-general-task-header .g_id {
        width: 5% !important;
    }

    .fixed-general-task-header .assignedg_name_box {
        width: 7% !important;
        text-align: start;
    }

    .assignedg_name_box {
        text-align: start;
        width: 8% !important;
    }

    .fixed-general-task-header .glocation_name_box {
        width: 7.1% !important;
    }

    .fixed-general-task-header .gtask_name_box {
        /* width: 30.5% !important; */
        /* width: 31.35% !important; */
        /* width: 26.4% !important; */
        width: 26.0% !important;
    }

    .fixed-general-task-header .grelatedto {
        /* width: 10.5% !important; */
        width: 9.04% !important;
    }

    .glocation_name_box {
        width: 7.9% !important;
        text-align: start;
    }

  

    .fixed-general-task-header .glocation_name_box {
        width: 7.5% !important;
        text-align: start;
    }

    .fixed-general-task-header .gdue-date {
        /* width: 10% !important; */
        width: 8% !important;
        text-align: start !important;
    }

    .fixed-general-task-header .gprioritys_col_box {
        width: 7.8% !important;
    }

    .fixed-general-task-header .gtask_action {
        width: 10% !important;
    }

    #generalTask .fix-table-header .gprioritys_col_box {
        text-align: start;
        width: 7% !important;
    }

    #generalTask .fix-table-header .gspent_hours-box {
        text-align: start;
    }

    #generalTask .fix-table-header .gspent_hours-box {
        text-align: start;
    }
}


@media screen and (min-width : 1200px) and (max-width : 1300px) {
    #support-header .support-action-part {
        width: 20% !important;
    }

    .customShortIcon {
        position: relative;
    }

    .customShortIcon .customIcon {
        position: absolute;
        top: 65%;
        right: 0px;
        transform: translateY(-50%);
    }


    .fixed-general-task-header .col-t-8 {
        width: 11% !important;
    }

    .gstatus_box {
        width: 13% !important;
    }

    .custom-text-center {
        text-align: center;
    }


    .g_id {
        text-align: start;
        width: 5% !important;
    }

    .fixed-general-task-header .g_id {
        width: 5% !important;
    }

    .fixed-general-task-header .assignedg_name_box {
        width: 7% !important;
    }

    .assignedg_name_box {
        text-align: start;
        width: 8% !important;
    }

    .fixed-general-task-header .glocation_name_box {
        width: 7.1% !important;
    }

    .fixed-general-task-header .gtask_name_box {
        /* width: 30.5% !important; */
        /* width: 31.35% !important; */
        /* width: 26.4% !important; */
        width: 23.0% !important;
    }

    .fixed-general-task-header .grelatedto {
        /* width: 10.5% !important; */
        width: 9.04% !important;
    }

    .glocation_name_box {
        width: 7.9% !important;
        text-align: start;
    }

    .fixed-general-task-header .glocation_name_box {
        width: 7.5% !important;
        text-align: start;
    }

    .fixed-general-task-header .gdue-date {
        /* width: 10% !important; */
        width: 8% !important;
    }

    .fixed-general-task-header .gprioritys_col_box {
        width: 7.8% !important;
    }

    .fixed-general-task-header .gtask_action {
        width: 10% !important;
    }

    #generalTask .fix-table-header .gprioritys_col_box {
        text-align: start;
        width: 7% !important;
    }

    #generalTask .fix-table-header .gspent_hours-box {
        text-align: start;
    }

    #generalTask .fix-table-header .gspent_hours-box {
        text-align: start;
    }
}

/* @media screen and (min-width : 1100px) and (max-width : 1200px){
    #support-header .general-action-part {
        width: 30% !important;
    }
    #support-header .general-status-part{
        width: 70% !important;
    }
  } */

@media screen and (min-width : 900px) {
    .general-task-status {
        width: 15% !important;
    }
}

.missedDeadline {
    background-color: #ffe8e8;
}

.lightcoral {
    background-color: #ffe8e8;

}

.add-task-button {
    position: fixed;
    top: 10px;
    right: 55px
}

/* ====================================================== */

.custom-Edit-Side {
    width: 15% !important;
}

.custom-right-side {
    width: 80% !important;
    margin-left: 17% !important;
}
.bg-light-red{
    background-color: #fff3f3 !important;
}