body {
  background-color: #f9f9fa;
}
.tokenValue-profile {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.bg-birthday {
  background-image: url("../../birthday/bg.avif");
  background-position: bottom;
}
.padding {
  padding: 3rem !important;
}

.user-card-full {
  overflow: hidden;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
  /* background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f29263),
    to(#ee5a6f)
  ); */
  /* background: linear-gradient(to right, #ee5a6f, #f29263); */
  background: linear-gradient(to right, #330148, #55236a);
}

/* //Profile section  */
.smallFont {
  font-size: 17px;
  word-break: keep-all;
}
.MidumFont {
  font-size: 1.42rem;
  word-break: keep-all;
}
.profile-section h5 {
  font-size: 15px;
}
.user-profile {
  padding: 20px 0;
}
.profile img {
  border-radius: 50%;
  width: 45%;
  min-height: 80px;
  height: auto;
  object-position: center !important;
  object-fit: cover;
}
@media (min-width: 1540px) {
  .user-profile h6 {
    margin-top: 32px;
  }
  .profile img {
    width: 30% !important;
    border-radius: 100%;
  }
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
}

h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

.document-heading {
  background: rgb(236, 232, 238);
  /* background: #F9F5FB; */
  color: #3c3939;
  /* border-radius: 10px; */
}
.document-heading h5 {
  font-size: 1.421875rem !important;
}
.document-card {
  background: rgb(236, 232, 238) !important;
}

/* // Experoes section  */
.experience-section h5 {
  font-size: 15px;
  font-weight: 600;
}

/* // Credentials Section */
.common-card {
  border-radius: 5px;
  background: rgb(236, 232, 238) !important;
}
.password-btn {
  padding: 3px 7px !important;
  margin-top: 1.8rem;
}
.input-wrapper {
  position: relative;
  display: inline-block;
}
.formInput {
  border-radius: 5px !important;
  position: relative;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.fa-copy {
  font-size: 14px !important;
}
.copyBtn {
  /* color: #af27ee !important; */
  color: rgb(90, 57, 121) !important;
}
.copyMsg {
  top: 0;
  right: 10px;
  font-weight: 600;
  /* background-color: #af27ee !important; */
  background-color: rgb(90, 57, 121) !important;
  color: white !important;
  padding: 3px 10px;
  border-radius: 5px;
}
.input-wrapper:hover .copy-btn {
  display: block !important;
}
/* .input-wrapper:hover .formInput{
  background-color: rgb(216, 210, 210);
} */
.copy-btn {
  background-color: transparent !important;
  /* color: #4d1b62 !important; */
  color: #7d03b6 !important;
  /* color: white !important; */
  position: absolute;
  top: 30%;
  right: 1%;
  transform: translateY(-50%);
  display: none !important;
  border: none !important;
  outline: none !important;
}

.Crediential-section h5 {
  font-size: 15px;
  font-weight: 600;
}

/* // Document Section  */

.docTitle {
  font-size: 15px;
  padding-top: 5px;
  font-weight: 600;
}

/* // Microsoft Integration Section */
.link-name {
  font-size: 15px;
  font-weight: 600;
  color: #74788d;
  /* text-transform: uppercase !important; */
}
.link-gerate-btn {
  /* padding: 4px 8px !important; */
  padding-block: 4px !important;
  background-color: #5c0385d6 !important;
  font-size: 15px !important;
}
.delete-Token-btn {
  background-color: red !important;
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px;
  }
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 25px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/* Work Experience css */

.work-activity {
  position: relative;
  color: #74788d;
  padding-left: 5.5rem;
}

.work-activity::before {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: 14.1%;
  border-left: 2px solid rgba(81, 31, 102, 0.25);
  /* border-left: 2px solid rgba(3, 142, 220, 0.25); */
}

.work-activity .work-item {
  position: relative;
  border-bottom: 2px dashed #eff0f2;
  margin-bottom: 14px;
}

.work-activity .work-item:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.work-activity .work-item::after,
.work-activity .work-item::before {
  position: absolute;
  display: block;
}

.work-activity .work-item::before {
  content: attr(data-date);
  left: -157px;
  top: -3px;
  text-align: right;
  font-weight: 500;
  color: #74788d;
  font-size: 12px;
  min-width: 120px;
}

.work-activity .work-item::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -26px;
  top: 3px;
  background-color: #fff;
  border: 2px solid #4d1b62;
}
@media (max-width: 1000px) {
  .work-activity::before {
    left: 20.8% !important;
  }
}
@media screen and (max-width: 1200px) {
  .work-activity::before {
    left: 17.3% !important;
  }
}
@media screen and (max-width: 1535px) {
  .work-activity::before {
    left: 14.3% !important;
  }
}
@media screen and (min-width: 1540px) {
  .work-activity::before {
    left: 12.3% !important;
  }
}
@media screen and (min-width: 1720px) {
  .work-activity::before {
    left: 9.5% !important;
  }
}
