/* #Roles .table > :not(caption) > * > * {
  padding: 1rem 0rem !important;
} */
.rolesIndexImg {
  position: fixed;
  width: 29.5%;
  top: 18%;
  /* transform: translateY(-20%); */
  transition: all 5s ease;
  /* box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; */

  /* padding: 0 1rem; */
}
.rolesIndexImg img {
  transition: all 5s ease;
  box-shadow: 0 0.75rem 2rem rgba(0, 0, 0, 0.125) !important;
}
.fix-header-roles-table {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 61.2%;
  /* left: 4.4%; */
}
#roles-table .t-head-1 {
  width: 3.8%;
}
#roles-table .t-head-2 {
  width: 29.2%;
}
#roles-table .t-head-3 {
  width: 8%;
}
#roles-table .t-head-4 {
  width: 7%;
}
.id-width-roles {
  width: 4%;
  text-align: center;
}

.role-name-width {
  width: 30%;
}
.role-status-width {
  width: 8%;
}
.action-roles {
  width: 7%;
  text-align: center;
}
/*  */
.container-fluid {
  max-width: 95% !important;
}
.role-table {
  table-layout: fixed;
}
.inner-table {
  background-color: #5c038505;
}
.cell-width {
  width: 12%;
}
.cell-width:last-child {
  width: 8%;
}
.form-switch-md .form-check-input {
  position: static !important;
}
.cell-width input {
  margin: auto !important;
}
.accordion-item {
  border-radius: 0 !important;
  border: none !important;
}

.accordian-box {
  position: relative;
}
.outer-toggle-btns {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 7.9%;
  width: 60.09%;
  height: 53px;
}
.outer-toggle-btns > div {
  position: relative;
  z-index: 30;
  width: 20.01%;
  padding-left: 4.45%;
  height: 100%;
  display: grid;
  place-items: center;
  border-left: 1px solid var(--bs-border-color);
}
.outer-toggle-btns > div:last-child {
  border-right: 1px solid var(--bs-border-color);
}
.accordian-btn {
  padding-right: 30px !important;
}
.fixed-header {
  position: fixed;
  top: 120px;
  z-index: 40;
  width: 75.3%;
  /* background-color: whitesmoke; */
  background-color: #981f77;
  color: white !important;
}
#Roles .fixed-header {
  left: 35.6%;
}
.fixed-header tr {
  display: flex;
  justify-content: space-between;
}
/* .fixed-header th {
  width: 12%;
} */
.fixed-header th:first-child {
  width: 31.7%;
}
.fixed-header th:nth-child(2) {
  width: 12%;
}
/* .fixed-header th:nth-child(2n+1) {
  width: 12.5%;
} */

.fixed-header th:nth-child(7) {
  width: 8%;
}
.saveBtn {
  background-color: #5c0385 !important;
  color: white;
  width: 6.4%;
  cursor: pointer;
}
.saveBtn i {
  font-size: 18px;
}
.bd-hover-inner tr:hover {
  background-color: #e5bbf7 !important;
  border-collapse: collapse;
}
.bg-grey {
  background-color: #dee4fc !important;
  border: none;
}
.bg-grey tr:nth-child(2n + 1) {
  background-color: #dee4fc;
}
.no-after::after {
  opacity: 0 !important;
}
.bd-transform-icon {
  transform: translateY(7px);
  display: inline-block;
  font-size: 25px;
  color: red;
}
.nav-side-item {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.bd-active-tab .active {
  background-color: #5c0385;
  color: white;
}
.bd-info-popup {
  max-width: 930px !important;
}
.bd-info-popup .modal-content {
  border: none;
}
.outer-toggle-btns input:disabled {
  opacity: 1 !important;
}
.bd-head-info {
  background-color: #eed1fb;
}
.bd-head-info h5 {
  text-transform: uppercase;
}
.bd-info-popup button {
  padding: 8px 35px;
  font-size: 14px;
}
.swal2-confirm {
  order: 2;
}
.swal2-cancel {
  order: 1;
}
#myModal-confirm {
  background-color: white;
  width: 30vw !important;
}
.warning-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999 !important;
  position: fixed !important;
  transform: translateX(50%) !important;
  /* transform: translateY(-50%) !important; */
  /* width: 100%;
  height: 100%; */
}

/* .heading-text-roles{
  padding-left: 2.5rem;
} */
@media (max-width: 768px) {
  .table {
    width: 650px !important;
  }
}

@media screen and (min-width : 1200px) and (max-width : 1400px) {
  .fixed-header{
    width: 73.4%;
  }
}
@media screen and (min-width : 1400px){
  .accordion-button{
    padding-right: 44px !important;
    text-align: center !important;
  }
}

.permission-close-box {
	max-height: 300px !important;
	overflow-y: auto;
	scrollbar-width: thin;
}
.drag-width{
  max-width: 96% !important;
}