.DevTask_table {
  width: 100% !important;
  margin-left: 25px;
}

.customfreeze {
  border-radius: none;
}

.approval-table {
  width: 95.5% !important;
  margin-left: 82px;
}

.package_id_width {
  width: 7% !important;
  text-align: center;
}

.country_width {
  width: 8% !important;
  text-align: center;
}

.package_name {
  width: 30% !important;
}

.activity_count {
  width: 12% !important;
  text-align: center;
}

.estimated_hourse {
  width: 12% !important;
  text-align: center;
}

.action_width {
  width: 8% !important;
  text-align: center;
}

/* Activity Tab  */
.activity_id_width {
  width: 2% !important;
  text-align: center;
}

.activity_name {
  width: 15% !important;
}

.estimate_time {
  width: 6% !important;
  text-align: center;
}

.activity_action_width {
  width: 5% !important;
  text-align: center;
}

/* Settigns Pckage tab  */
.setting_package_box {
  max-height: 400px !important;
  overflow-y: auto;
  scrollbar-width: thin;
}

.digital_marketing_sidebar {
  position: fixed !important;
  width: 18% !important;
  /* width: 5% !important; */
}

.approval_sidebar {
  position: fixed !important;
  width: 18% !important;
}

.dmPackageNav {
  margin-top: 26%;
}

/* // Project Plan / Development  */
.pmDevSidebar {
  margin-top: 13% !important;
}

/* ================================ Development Section ================================ */

.fixed-table-header {
  background-color: white;
}

.fixed_addMile_header tr {
  display: flex;
}

.fixed_addMile_header {
  position: fixed;
  top: 120px;
  width: 68.2%;
  background-color: #981f77;
  color: white;
}

.fixed_addTask_header tr {
  display: flex;
}

.fixed_addTask_header {
  position: fixed;
  top: 120px;
  width: 60.4%;
  background-color: #981f77;
  color: white;
}

.fixed_daysEstimation_header tr {
  display: flex;
}

.fixed_daysEstimation_header {
  position: fixed;
  top: 120px;
  width: 68.4%;
  background-color: #981f77;
  color: white;
}

.fixed_approval_process_header tr {
  display: flex;
}

.fixed_approval_process_header {
  position: fixed;
  top: 120px;
  width: 60.1%;
  background-color: #981f77;
  color: white;
}

.fixed_complete_process_header tr {
  display: flex;
}

.fixed_complete_process_header {
  position: fixed;
  top: 120px;
  width: 68.3%;
  background-color: #981f77;
  color: white;
}

/* Milestone Tab  */
.mil_id {
  width: 5% !important;
  text-align: center;
}

.fixed_addMile_header .mil_id {
  width: 7.5% !important;
}

.mil_name {
  width: 8% !important;
  text-align: center;
}

.fixed_addMile_header .mil_name {
  width: 12.3% !important;
}

.mile_hrs {
  width: 5% !important;
  text-align: center;
}

.fixed_addMile_header .mile_hrs {
  width: 7.5% !important;
}

.mile_desc {
  width: 40% !important;
}

.fixed_addMile_header .mile_desc {
  width: 60.6% !important;
}

.mile_action {
  width: 8% !important;
  text-align: center;
}

.fixed_addMile_header .mile_action {
  width: 12% !important;
}

/* Add Task  */
.t_id {
  width: 5% !important;
  text-align: center;
}

.fixed_addTask_header .t_id {
  width: 9% !important;
}

.t_name {
  width: 30% !important;
}

.fixed_addTask_header .t_name {
  width: 55% !important;
}

.t_assign {
  width: 5% !important;
  text-align: center;
}

.fixed_addTask_header .t_assign {
  width: 9.5% !important;
}

.t_est_hrs {
  width: 8% !important;
  text-align: center;
}

.fixed_addTask_header .t_est_hrs {
  width: 15% !important;
}

.t_action {
  width: 5% !important;
  text-align: center;
}

.fixed_addTask_header .t_action {
  width: 13% !important;
}

/* Days Estimation  */
.de_id {
  width: 5% !important;
  text-align: center;
}

.fixed_daysEstimation_header .de_id {
  width: 6.2% !important;
}

.de_title {
  width: 35% !important;
}

.fixed_daysEstimation_header .de_title {
  width: 45.2% !important;
}

.de_milestone {
  width: 8.5% !important;
  text-align: center;
}

.fixed_daysEstimation_header .de_milestone {
  width: 11.2% !important;
}

.de_assign {
  width: 6% !important;
}

.fixed_daysEstimation_header .de_assign {
  width: 7.5% !important;
}

.de_kam {
  width: 4.9% !important;
  text-align: center;
}

.fixed_daysEstimation_header .de_kam {
  width: 6.4% !important;
}

.es_hrs {
  width: 5% !important;
  text-align: center;
}

.fixed_daysEstimation_header .es_hrs {
  width: 6.6% !important;
}

.es_prob {
  width: 5% !important;
  text-align: center;
}

.fixed_daysEstimation_header .es_prob {
  width: 6% !important;
}

.es_action {
  width: 8% !important;
  text-align: center;
}

.fixed_daysEstimation_header .es_action {
  width: 11% !important;
}

/* Apprvoal Process  */
.pmApprovalSidebar {
  margin-top: 28% !important;
}

.a_id {
  width: 4% !important;
  text-align: center;
}

.fixed_approval_process_header .a_id {
  width: 8% !important;
}

.a_name {
  width: 18% !important;
}

.fixed_approval_process_header .a_name {
  width: 37% !important;
}

.a_milestone {
  width: 2% !important;
  text-align: start;
}

.fixed_approval_process_header .a_milestone {
  width: 9% !important;
}

.a_assign {
  width: 5% !important;
}

.fixed_approval_process_header .a_assign {
  width: 10% !important;
}

.a_assign img {
  width: 50% !important;
}

.a_kam_hrs {
  width: 5% !important;
  text-align: center;
}

.fixed_approval_process_header .a_kam_hrs {
  width: 10% !important;
}

.a_developers_days {
  width: 5% !important;
  text-align: center;
}

.fixed_approval_process_header .a_developers_days {
  width: 10% !important;
}

.a_final_days {
  width: 3% !important;
  text-align: center;
}

.fixed_approval_process_header .a_final_days {
  width: 8% !important;
}

.a_action {
  width: 3% !important;
  text-align: center;
}

.fixed_approval_process_header .a_action {
  width: 10.5% !important;
}

/* Completed Process  */
.com_id {
  width: 3% !important;
  text-align: center;
}

.fixed_complete_process_header .com_id {
  width: 6% !important;
}

.com_title {
  width: 30% !important;
}

.fixed_complete_process_header .com_title {
  width: 62.5% !important;
}

.com_milestone {
  width: 6% !important;
  text-align: center;
}

.fixed_complete_process_header .com_milestone {
  width: 13% !important;
}

.com_assign {
  width: 4% !important;
  text-align: center;
}

.fixed_complete_process_header .com_assign {
  width: 8% !important;
}

.com_assign img {
  width: 40% !important;
}

.com_es_hrs {
  width: 5% !important;
  text-align: center;
}

.fixed_complete_process_header .com_es_hrs {
  width: 11% !important;
}

.customShortIcon .customApprovalIcon {
  position: absolute;
  top: 60%;
  right: 3px;
  /* transform: translateY(-50%); */
}

/* ================================================ Meida Screen 1000 to 1200 ================================================ */

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  /* Milestone Screen  */
  .mil_id {
    width: 7% !important;
    text-align: start;
  }

  .fixed_addMile_header .mil_id {
    width: 11.2% !important;
  }

  .mil_name {
    width: 15% !important;
  }

  .fixed_addMile_header .mil_name {
    width: 21.3% !important;
  }

  .mile_hrs {
    width: 6% !important;
    text-align: center;
  }

  .fixed_addMile_header .mile_hrs {
    width: 9.5% !important;
  }

  .mile_desc {
    width: 25% !important;
  }

  .fixed_addMile_header .mile_desc {
    width: 45.6% !important;
  }

  .mile_action {
    width: 13% !important;
    text-align: center;
  }

  .fixed_addMile_header .mile_action {
    width: 25% !important;
  }

  /* Add Task Screen  */
  .customShortIcon .customIcon {
    position: absolute;
    top: 70%;
    right: 10px;
  }

  #credentialPage .nav-link {
    width: 22%;
  }

  .resp_show {
    margin-right: 30px !important;
  }

  .t_id {
    width: 7% !important;
    text-align: start;
  }

  .t_name {
    width: 25% !important;
  }

  .t_assign {
    width: 10% !important;
    text-align: start;
  }

  .t_est_hrs {
    width: 2% !important;
    text-align: center;
  }

  .t_action {
    width: 4% !important;
    text-align: center;
  }

  /* Days Estimation screen  */
  .de_id {
    width: 9% !important;
  }

  .de_title {
    width: 30% !important;
  }

  .de_milestone {
    width: 14% !important;
    text-align: start;
  }

  .de_assign {
    width: 12% !important;
  }

  .de_kam {
    width: 12% !important;
  }

  .es_hrs {
    width: 7% !important;
    text-align: center;
  }

  .es_prob {
    width: 7% !important;
    text-align: center;
  }

  .es_action {
    width: 5% !important;
    text-align: center;
  }

  /* Approval Process  */
  .a_id {
    width: 5% !important;
    text-align: start;
  }

  .a_name {
    width: 25% !important;
  }

  .a_milestone {
    width: 10% !important;
    text-align: center;
  }

  .a_assign {
    width: 10% !important;
  }

  .a_assign img {
    width: 55% !important;
  }

  .a_kam_hrs {
    width: 6% !important;
    text-align: center;
  }

  .a_kam_hrs {
    width: 5% !important;
    text-align: center;
  }

  .a_developers_days {
    width: 5% !important;
    text-align: center;
  }

  .a_final_days {
    width: 12% !important;
    text-align: center;
  }

  .a_action {
    width: 4% !important;
    text-align: center;
  }

  /* Complete Process  */
  .com_id {
    width: 6% !important;
    text-align: center;
  }

  .com_title {
    width: 25% !important;
    text-align: start;
  }

  .com_milestone {
    width: 5% !important;
    text-align: start;
  }

  .com_assign {
    width: 5% !important;
    text-align: start;
  }

  .com_es_hrs {
    width: 2% !important;
    text-align: center;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  /* Add Milestone Screen  */
  .mil_id {
    width: 6% !important;
  }

  .mil_name {
    width: 7% !important;
    text-align: start;
  }

  .mile_hrs {
    width: 5% !important;
    text-align: start;
  }

  .mile_desc {
    width: 30% !important;
  }

  .mile_action {
    width: 13% !important;
    text-align: center;
  }

  /* Add Task Screen  */
  .customShortIcon .customIcon {
    position: absolute;
    top: 70%;
    right: 10px;
  }

  #credentialPage .nav-link {
    width: 22%;
  }

  .resp_show {
    margin-right: 22px !important;
  }

  .t_id {
    width: 8% !important;
    text-align: center;
  }

  .t_name {
    width: 30% !important;
  }

  .t_assign {
    width: 11% !important;
    text-align: center;
  }

  .t_est_hrs {
    width: 2% !important;
    text-align: start;
  }

  .t_action {
    width: 4% !important;
    text-align: center;
  }

  /* Days Estimation screen  */
  .de_id {
    width: 9% !important;
  }

  .de_title {
    width: 35% !important;
  }

  .de_milestone {
    width: 14% !important;
    text-align: start;
  }

  .de_assign {
    width: 11% !important;
  }

  .de_kam {
    width: 9.5% !important;
  }

  .es_hrs {
    width: 7% !important;
    text-align: center;
  }

  .es_prob {
    width: 7% !important;
    text-align: center;
  }

  .es_action {
    width: 5% !important;
    text-align: center;
  }

  /* Approval Process  */
  .a_id {
    width: 7% !important;
    text-align: start;
  }

  .a_name {
    width: 25% !important;
  }

  .a_milestone {
    width: 8% !important;
    text-align: start;
  }

  .a_assign {
    width: 11.5% !important;
  }

  .a_assign img {
    width: 50% !important;
  }

  .a_kam_hrs {
    width: 6% !important;
    text-align: center;
  }

  .a_developers_days {
    width: 6% !important;
    text-align: center;
  }

  .a_final_days {
    width: 11% !important;
    text-align: center;
  }

  .a_action {
    width: 4% !important;
    text-align: center;
  }

  /* Complete Process  */
  .com_id {
    width: 5% !important;
    text-align: center;
  }

  .com_title {
    width: 25% !important;
    text-align: start;
  }

  .com_milestone {
    width: 3% !important;
    text-align: start;
  }

  .com_assign {
    width: 3% !important;
    text-align: start;
  }

  .com_assign img {
    width: 50% !important;
  }

  .com_es_hrs {
    width: 2% !important;
    text-align: center;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .mil_id {
    width: 5% !important;
  }

  .fixed_addMile_header .mil_id {
    width: 8.5% !important;
  }

  .mil_name {
    width: 7% !important;
    text-align: start;
  }

  .fixed_addMile_header .mil_name {
    width: 13.3% !important;
  }

  .mile_hrs {
    width: 5% !important;
    text-align: start;
  }

  .fixed_addMile_header .mile_hrs {
    width: 9.5% !important;
  }

  .mile_desc {
    width: 30% !important;
  }

  .fixed_addMile_header .mile_desc {
    width: 50.6% !important;
  }

  .mile_action {
    width: 10% !important;
    text-align: center;
  }

  .fixed_addMile_header .mile_action {
    width: 18% !important;
  }

  /* Add Task Screen  */
  .customShortIcon .customIcon {
    position: absolute;
    top: 70%;
    right: 10px;
  }

  #credentialPage .nav-link {
    width: 22%;
  }

  .resp_show {
    margin-right: 22px !important;
  }

  .t_id {
    width: 7% !important;
    text-align: center;
  }

  .fixed_addTask_header .t_id {
    width: 12% !important;
  }

  .t_name {
    width: 30% !important;
  }

  .fixed_addTask_header .t_name {
    width: 51% !important;
  }

  .t_assign {
    width: 9% !important;
    text-align: center;
  }

  .fixed_addTask_header .t_assign {
    width: 12.5% !important;
  }

  .t_est_hrs {
    width: 2% !important;
    text-align: center;
  }

  .fixed_addTask_header .t_est_hrs {
    width: 12% !important;
  }

  .t_action {
    width: 4% !important;
    text-align: center;
  }

  .fixed_addTask_header .t_action {
    width: 14% !important;
  }

  /* Days Estimation screen  */
  .de_id {
    width: 9% !important;
  }

  .fixed_daysEstimation_header .de_id {
    width: 8.2% !important;
  }

  .de_title {
    width: 35% !important;
  }

  .fixed_daysEstimation_header .de_title {
    width: 34.2% !important;
  }

  .de_milestone {
    width: 14% !important;
    text-align: start;
  }

  .fixed_daysEstimation_header .de_milestone {
    width: 13.2% !important;
  }

  .de_assign {
    width: 11% !important;
  }

  .fixed_daysEstimation_header .de_assign {
    width: 10.5% !important;
  }

  .de_kam {
    width: 9.5% !important;
  }

  .fixed_daysEstimation_header .de_kam {
    width: 8.5% !important;
  }

  .es_hrs {
    width: 7% !important;
    text-align: center;
  }

  .fixed_daysEstimation_header .es_hrs {
    width: 8.6% !important;
  }

  .es_prob {
    width: 7% !important;
    text-align: center;
  }

  .fixed_daysEstimation_header .es_prob {
    width: 9% !important;
  }

  .es_action {
    width: 5% !important;
    text-align: center;
  }

  .fixed_daysEstimation_header .es_action {
    width: 8% !important;
  }

  /* Approval Process  */
  #credentialPage .approval_sidebar .nav-link {
    width: 28% !important;
  }

  .a_id {
    width: 7% !important;
    text-align: start;
  }

  .fixed_approval_process_header .a_id {
    width: 8% !important;
  }

  .a_name {
    width: 25% !important;
  }

  .fixed_approval_process_header .a_name {
    width: 29% !important;
  }

  .a_milestone {
    width: 10% !important;
    text-align: start;
  }

  .fixed_approval_process_header .a_milestone {
    width: 12% !important;
  }

  .a_assign {
    width: 10.5% !important;
  }

  .fixed_approval_process_header .a_assign {
    width: 11% !important;
  }

  .a_assign img {
    width: 50% !important;
  }

  .a_kam_hrs {
    width: 10% !important;
    text-align: center;
  }

  .fixed_approval_process_header .a_kam_hrs {
    width: 10% !important;
  }

  .a_developers_days {
    width: 10% !important;
    text-align: center;
  }

  .fixed_approval_process_header .a_developers_days {
    width: 10% !important;
  }

  .a_final_days {
    width: 8% !important;
    text-align: center;
  }

  .fixed_approval_process_header .a_final_days {
    width: 8% !important;
  }

  .a_action {
    width: 4% !important;
    text-align: center;
  }

  .fixed_approval_process_header .a_action {
    width: 11.5% !important;
  }

  /* Complete Process  */
  .com_id {
    width: 3% !important;
    text-align: center;
  }

  .fixed_complete_process_header .com_id {
    width: 7% !important;
  }

  .com_title {
    width: 30% !important;
    text-align: start;
  }

  .fixed_complete_process_header .com_title {
    width: 58.5% !important;
  }

  .com_milestone {
    width: 5% !important;
    text-align: start;
  }

  .fixed_complete_process_header .com_milestone {
    width: 12% !important;
  }

  .com_assign {
    width: 5% !important;
    text-align: start;
  }

  .fixed_complete_process_header .com_assign {
    width: 10% !important;
  }

  .com_assign img {
    width: 50% !important;
  }

  .com_es_hrs {
    width: 5% !important;
    text-align: center;
  }

  .fixed_complete_process_header .com_es_hrs {
    width: 12% !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .mil_id {
    width: 5% !important;
  }

  .fixed_addMile_header .mil_id {
    width: 8.5% !important;
  }

  .mil_name {
    width: 7% !important;
    text-align: start;
  }

  .fixed_addMile_header .mil_name {
    width: 14.3% !important;
  }

  .mile_hrs {
    width: 5% !important;
    text-align: start;
  }

  .fixed_addMile_header .mile_hrs {
    width: 10.5% !important;
  }

  .mile_desc {
    width: 30% !important;
  }

  .fixed_addMile_header .mile_desc {
    width: 50.6% !important;
  }

  .mile_action {
    width: 8% !important;
    text-align: center;
  }

  .fixed_addMile_header .mile_action {
    width: 12% !important;
  }

  /* Add Task Screen  */
  .customShortIcon .customIcon {
    position: absolute;
    top: 70%;
    right: 10px;
  }

  #credentialPage .nav-link {
    width: 22%;
  }

  .resp_show {
    margin-right: 15px !important;
  }

  .t_id {
    width: 6% !important;
    text-align: center;
  }

  .fixed_addTask_header .t_id {
    width: 10% !important;
  }

  .t_name {
    width: 35% !important;
  }

  .fixed_addTask_header .t_name {
    width: 57% !important;
  }

  .t_assign {
    width: 9% !important;
    text-align: center;
  }

  .fixed_addTask_header .t_assign {
    width: 12.5% !important;
  }

  .t_est_hrs {
    width: 2% !important;
    text-align: center;
  }

  .fixed_addTask_header .t_est_hrs {
    width: 11% !important;
  }

  .t_action {
    width: 4% !important;
    text-align: center;
  }

  .fixed_addTask_header .t_action {
    width: 14% !important;
  }

  /* Days Estimation screen  */
  .de_id {
    width: 9% !important;
  }

  .fixed_daysEstimation_header .de_id {
    width: 9.2% !important;
  }

  .de_title {
    width: 35% !important;
  }

  .fixed_daysEstimation_header .de_title {
    width: 34.2% !important;
  }

  .de_milestone {
    width: 14% !important;
    text-align: start;
  }

  .fixed_daysEstimation_header .de_milestone {
    width: 14.2% !important;
  }

  .de_assign {
    width: 11% !important;
  }

  .fixed_daysEstimation_header .de_assign {
    width: 10.5% !important;
  }

  .de_kam {
    width: 8.5% !important;
  }

  .fixed_daysEstimation_header .de_kam {
    width: 8.4% !important;
  }

  .es_hrs {
    width: 7% !important;
    text-align: center;
  }

  .fixed_daysEstimation_header .es_hrs {
    width: 7.6% !important;
  }

  .es_prob {
    width: 7% !important;
    text-align: center;
  }

  .fixed_daysEstimation_header .es_prob {
    width: 7.5% !important;
  }

  .es_action {
    width: 5% !important;
    text-align: center;
  }

  .fixed_daysEstimation_header .es_action {
    width: 9% !important;
  }

  /* Approval Process  */
  #credentialPage .approval_sidebar .nav-link {
    width: 28% !important;
  }

  .a_id {
    width: 7% !important;
    text-align: start;
  }

  .fixed_approval_process_header .a_id {
    width: 8% !important;
  }

  .a_name {
    width: 25% !important;
  }

  .fixed_approval_process_header .a_name {
    width: 27% !important;
  }

  .a_milestone {
    width: 10% !important;
    text-align: start;
  }

  .fixed_approval_process_header .a_milestone {
    width: 11% !important;
  }

  .a_assign {
    width: 10.5% !important;
  }

  .fixed_approval_process_header .a_assign {
    width: 11% !important;
  }

  .a_assign img {
    width: 50% !important;
  }

  .a_kam_hrs {
    width: 10% !important;
    text-align: center;
  }

  .fixed_approval_process_header .a_kam_hrs {
    width: 11% !important;
  }

  .a_developers_days {
    width: 10% !important;
    text-align: center;
  }

  .fixed_approval_process_header .a_developers_days {
    width: 11% !important;
  }

  .a_final_days {
    width: 9% !important;
    text-align: center;
  }

  .fixed_approval_process_header .a_final_days {
    width: 9% !important;
  }

  .a_action {
    width: 4% !important;
    text-align: center;
  }

  .fixed_approval_process_header .a_action {
    width: 11.5% !important;
  }

  /* Complete Process  */
  .com_id {
    width: 3% !important;
    text-align: center;
  }

  .fixed_complete_process_header .com_id {
    width: 7% !important;
  }

  .com_title {
    width: 30% !important;
    text-align: start;
  }

  .fixed_complete_process_header .com_title {
    width: 58.5% !important;
  }

  .com_milestone {
    width: 5% !important;
    text-align: start;
  }

  .fixed_complete_process_header .com_milestone {
    width: 12% !important;
  }

  .com_assign {
    width: 5% !important;
    text-align: start;
  }

  .fixed_complete_process_header .com_assign {
    width: 10% !important;
  }

  .com_assign img {
    width: 50% !important;
  }

  .com_es_hrs {
    width: 5% !important;
    text-align: center;
  }

  .fixed_complete_process_header .com_es_hrs {
    width: 13% !important;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .mil_id {
    width: 5% !important;
  }

  .fixed_addMile_header .mil_id {
    width: 8.5% !important;
  }

  .mil_name {
    width: 7% !important;
    text-align: start;
  }

  .fixed_addMile_header .mil_name {
    width: 13.3% !important;
  }

  .mile_hrs {
    width: 5% !important;
    text-align: start;
  }

  .fixed_addMile_header .mile_hrs {
    width: 10.5% !important;
  }

  .mile_desc {
    width: 30% !important;
  }

  .fixed_addMile_header .mile_desc {
    width: 52.6% !important;
  }

  .mile_action {
    width: 8% !important;
    text-align: center;
  }

  .fixed_addMile_header .mile_action {
    width: 15% !important;
  }

  /* Add Task Screen  */
  .customShortIcon .customIcon {
    position: absolute;
    top: 70%;
    right: 10px;
  }

  #credentialPage .nav-link {
    width: 22%;
  }

  .resp_show {
    margin-right: 15px !important;
  }

  .t_id {
    width: 6% !important;
    text-align: center;
  }

  .t_name {
    width: 40% !important;
  }

  .t_assign {
    width: 9% !important;
    text-align: center;
  }

  .t_est_hrs {
    width: 2% !important;
    text-align: center;
  }

  .t_action {
    width: 4% !important;
    text-align: center;
  }

  /* Days Estimation screen  */
  .de_id {
    width: 7% !important;
  }

  .de_title {
    width: 35% !important;
  }

  .de_milestone {
    width: 10% !important;
    text-align: start;
  }

  .de_assign {
    width: 9% !important;
  }

  .de_kam {
    width: 6.9% !important;
  }

  .es_hrs {
    width: 7% !important;
    text-align: center;
  }

  .es_prob {
    width: 7% !important;
    text-align: center;
  }

  .es_action {
    width: 5% !important;
    text-align: center;
  }

  /* Approval Process  */
  #credentialPage .approval_sidebar .nav-link {
    width: 25% !important;
  }

  .a_id {
    width: 5.5% !important;
    text-align: start;
  }

  .a_name {
    width: 30% !important;
  }

  .a_milestone {
    width: 11% !important;
    text-align: start;
  }

  .a_assign {
    width: 8.5% !important;
  }

  .a_assign img {
    width: 50% !important;
  }

  .a_kam_hrs {
    width: 5% !important;
    text-align: center;
  }

  .a_developers_days {
    width: 5% !important;
    text-align: center;
  }

  .a_final_days {
    width: 8% !important;
    text-align: center;
  }

  .a_action {
    width: 4% !important;
    text-align: center;
  }

  /* Complete Process  */
  .com_id {
    width: 3% !important;
    text-align: center;
  }

  .com_title {
    width: 30% !important;
    text-align: start;
  }

  .com_milestone {
    width: 2% !important;
    text-align: start;
  }

  .com_assign {
    width: 2% !important;
    text-align: start;
  }

  .com_assign img {
    width: 50% !important;
  }

  .com_es_hrs {
    width: 2% !important;
    text-align: center;
  }
}

.bg-fail {
  background-color: #ffebeb !important;
}

.report_id {
  width: 2% !important;
  text-align: center;
}

@media screen and (max-width: 1700px) {
  .digital_marketing_sidebar {
    position: fixed !important;
    width: 25% !important;
    z-index: 999;
    left: 16.5%;
  }

  .approval_sidebar {
    position: fixed !important;
    width: 20% !important;
  }
}

.resource-hours {
  width: 65%;
  border-radius: 5px;
  color: #f46a6a;
  margin-left: 5px;
  font-size: 1em;
  position: absolute;
  top: 0;
  z-index: 999;
}
.avalaible-hrs {
  position: absolute;
  height: 20px;
  top: 0;
  width: 100%;
}

.fc .fc-daygrid-day-top {
  background-color: #f0d3fd;
  margin-left: 2px !important;
  margin-bottom: 3px;
}
.time-header {
  font-size: 13.5px;
  color: #ff4500;
  margin-bottom: 5px;
}

.fc-currentStatus-button {
  background-color: #50a5f1 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  border-radius: 3px !important;
  border: none !important;
}
.fc-completeSprint-button {
  background-color: #4dca9d !important; 
  border-radius: 3px !important;
  border: none !important;
}

.sr_col {
  width: 4% !important;
}
.activity_col {
  width: 50% !important;
}
.totalQty_col {
  width: 6% !important;
}
.complQty_col {
  width: 7% !important;
}
.act_id {
  width: 90px !important;
}
.act_col {
  width: 250px !important;
}
.custom_AllActivity_popup {
  max-width: 95% !important;
  width: 95% !important;
}
.custom_checking_popup {
  max-width: 95% !important;
  width: 95% !important;
  min-height: 500px !important;
}

.custom_checking_report_popup {
  max-width: 60% !important;
  width: 60% !important;
  min-height: 400px !important;
}
.all_activity_table {
  width: 100% !important;
  overflow-x: scroll !important;
}
.all_activity_box {
  max-width: 100% !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
  scrollbar-width: thin;
}
