#customInputBox {
  height: 120px !important;
}

.disableCKEditor {
  pointer-events: none !important;
  opacity: 0.6;
  background-color: grey !important;
}

.custom_subtask_box {
  overflow-y: scroll;
  scrollbar-width: thin;
}

.dg_id {
  width: 2% !important;
  text-align: center;
}

.dg_added_by {
  width: 2% !important;
  text-align: center;
}

.dg-related {
  width: 1% !important;
  text-align: center;
}

.dg_project_name {
  width: 20% !important;
}

.dg-com-status {
  width: 12% !important;
}

.pl_status-id {
  width: 3.5% !important;
}

.dg_type_name {
  width: 7% !important;
  text-align: center;
}

.dg_task_name {
  width: 40% !important;
}

.dg_end_date {
  width: 3% !important;
  text-align: center;
}

.dg_last_update {
  width: 4.5% !important;
  text-align: center;
}

.activity-action {
  width: 3% !important;
  text-align: center;
}

.dg_added_by {
  width: 3% !important;
  text-align: center;
}

.tl_id {
  width: 2% !important;
  text-align: center;
}

.dg-status {
  width: 8% !important;
  text-align: center;
}

.dg-action {
  width: 8% !important;
  text-align: center;
}

/* ADD tASK POPUP  */
.DG_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  z-index: 100;
  transition: all 0.5s ease;
}

.overflow-visible .simplebar-content-wrapper {
  overflow: visible !important;
}

.custom-checkbox {
  transform: scale(2.15);
  margin-right: 20px;
  margin-top: 9px !important;
  border-radius: 3px !important;
}

#customCheckbox .form-check-input {
  border-radius: 2px !important;
}

#customCheckbox .form-check-input {
  background-color: white !important;
  border: 1px solid green !important;
}

#customCheckbox .form-check-input:checked {
  background-color: green !important;
  border: 1px solid green !important;
}

.color-orange {
  color: #ff9309;
}

.custim-width {
  width: 3% !important;
}

.pl_id {
  width: 1% !important;
  text-align: center;
}

.pl_task_name {
  width: 30% !important;
}

.pl_start_date {
  width: 6% !important;
  text-align: center;
}

.pl_end_date {
  width: 2% !important;
  text-align: center;
}

.pl_priority {
  width: 2% !important;
  text-align: center;
}

.pl_status_col {
  width: 6% !important;
  text-align: center;
}

.pl_es_time {
  width: 7% !important;
  text-align: center;
}

.pl-esttime {
  width: 4% !important;
  text-align: center;
}

.pln_Day_popup {
  max-height: 80vh !important;
  overflow-y: auto;
  scrollbar-width: thin;
}

.new-dashboard-task-modal {
  max-height: 700px !important;
  overflow-y: auto;
  scrollbar-width: none;
}

.general-status {
  width: 10px !important;
  height: 10px !important;
}

.plan-tab {
  width: 12% !important;
}

.plan-right-tab {
  width: 85% !important;
}

.total-est {
  text-align: right;
  transform: translateY(20px);
  line-height: 0;
}

.status-complete {
  background-color: #b9ffb982 !important;
}

.custom_navTab {
  overflow-x: scroll;
  scrollbar-width: none;
  width: 100% !important;
}

.custom_navItem {
  /* background-color: #ecececa6; */
  background-color: #f0e8f4;
  width: 30% !important;
  color: #330148 !important;
}

/* Report Styling  */
.bd-report-float {
  top: 50%;
  -webkit-transform: rotate(90deg);
  transform: rotate(-90deg);
}

.bd-report-float:hover {
  color: white;
}

.floating-btn {
  bottom: 45%;
  position: fixed;
  right: -45px;
  z-index: 999;
}

.report-width {
  width: 60%;
  top: 18%;
  z-index: 999;
}

.report-height {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.tabs {
  padding: 0.5rem 1rem;
  background-color: #f0e8f4;
  border-radius: 2px;
  font-weight: 500;
  margin-bottom: 5px;
}

.added-tabs {
  min-width: 225px;
}

.tabs a {
  color: #330148;
}

.tabs .edit-dashboard i {
  font-size: 7.5px;
}

.tabs .edit-dashboard {
  line-height: 0;
}

.tabs.active {
  background-color: #752a97;
  color: white;
}

.tabs.active a {
  color: white;
}

.dashboard-tabs {
  width: 97%;
  overflow-x: auto;
  scrollbar-width: thin;
}

.add-dahsboard-tab {
  width: 3.5%;
}

/* Task Table */
.task_ids {
  width: 7%;
}

.main-dash {
  height: 380px;
}

.bg-orange-task {
  background-color: #fff3de52 !important;
}

.wel-img {
  height: 114px !important;
  width: auto;
  object-fit: cover;
}

.icon-hot {
  font-size: 18px;
  line-height: 0;
  color: rgb(255, 87, 51);
  line-height: 0;
}

.icon-flame {
  font-size: 20px;
  line-height: 0;
  color: rgb(253, 171, 153);
}

.icon-sun {
  font-size: 20px;
  line-height: 0;
  color: rgb(74, 179, 255);
}

.general-box {
  padding: 5px 12px;
  background-color: #f0e8f4;
  border-radius: 5px;
}

.general-box h5 {
  font-size: 13px;
}

.general-box.bg-purple h5 {
  color: white !important;
}

.general-box.bg-light-red {
  background-color: #ffe8e8;
}

.general-box.bg-light-red.bg-purple {
  background-color: #bc1e30 !important;
}

.related-to-width {
  width: 40% !important;
}

.line-height-0 {
  line-height: 0;
}

.mt-2im {
  margin-top: 5px !important;
}

.dm-overview-width {
  width: 8% !important;
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .general-box {
    padding: 5px 4px !important;
    background-color: #f0e8f4;
    border-radius: 5px;
  }

  .taskbox {
    width: 11% !important;
  }

  .followupBox {
    width: 16% !important;
  }

  .newBox {
    width: 9% !important;
  }

  .todaysBox {
    width: 18% !important;
  }

  .general-box h5 {
    font-style: 12px !important;
  }

  .cusrtom_search_box {
    width: 30% !important;
  }

  .savelayout {
    animation: fadeIn 1s ease-out;
    background-color: rgb(63, 117, 210) !important;
  }
}
.custom_layout_cls {
  /* background-color: rgb(170, 180, 170); */
  display: flex;
  flex-direction: row !important;
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .general-box {
    padding: 5px 4px !important;
    background-color: #f0e8f4;
    border-radius: 5px;
  }

  .taskbox {
    width: 16% !important;
  }

  .followupBox {
    width: 25% !important;
  }

  .newBox {
    width: 16% !important;
  }

  .todaysBox {
    width: 26% !important;
  }

  .general-box h5 {
    font-style: 12px !important;
  }

  .cusrtom_search_box {
    width: 37% !important;
  }
}

.cusrtom_search_box {
  width: 33% !important;
}

/* Swipe And Issues Sections  */

.db_swipe_id {
  width: 3% !important;
  text-align: center !important;
}

.db_swipe_name {
  width: 40% !important;
  text-align: start !important;
}

.db_swipe_name_box img {
  width: 32px !important;
  height: 32px !important;
  object-fit: cover !important;
}

.db_swipe_actions {
  width: 3% !important;
  text-align: center !important;
}

.bg-light-red2 {
  /* background-color: #ffe8e8 !important; */
  background-color: #fcf4f4 !important;
  /* background: #f6c4c4; */
}

.bg-light-blue2 {
  background-color: #e3e5f3 !important;
}

.user_img_da {
  width: 5.5% !important;
  border-radius: 100% !important;
}

.noti_1 {
  /* background-color: #885fc3; */
  background-color: rgb(244, 242, 242);
  color: black;
  padding: 5px;
}

.noti_2 {
  /* background-color: #af89e6; */
  background-color: white;
  color: black;
  padding: 5px;
}

.dahboard_swipe span {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.db_swipe_date {
  width: 3% !important;
  text-align: center !important;
}

.absent-length {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #53176c;
  border-radius: 27px;
}
.dashboard-tabs {
  width: 92%;
  overflow-x: auto;
  scrollbar-width: thin;
}
.add-dahsboard-tab {
  width: 9%;
  display: flex;
  justify-content: end;
  align-items: center;
}

/* //============================== Drag and Drop  ==============================// */

.react-grid-item {
  z-index: 1; /* Lower than your popups */
}

/* .react-draggable-transparent-selection{
    
   } */

.drag_drop_comp .single_comp {
  position: relative;
  /* z-index: 0 !important; */
  /* transform: none !important; */
}
.drag_drop_comp .moveIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  padding: 5px 10px;
}

.drag_drop_comp .moveIcon i {
  font-size: 11px;
  color: gray;
}

.drag_drop_comp .inner_component {
  width: 100%;
  height: 100%;
  overflow: scroll;
  scrollbar-width: none;
}

.drag_drop_comp .classname1 {
  padding: none !important;
}

.tabs-widths {
  width: 96%;
}

.loading-button:hover span {
  transform: translateY(-3px);
}
.loading-button:focus {
  outline: none;
}

.loading-button span {
  transition: 1s;
  transition-delay: 1s;
}

.loading-button.loading span {
  top: -50px;
  right: 0;
  display: none;
}

.loading-button.success span {
  top: -50px;
}

.loading-button.success {
  pointer-events: none;
}

.loading-button.loading {
  display: block;
  width: 28px;
  border-radius: 50%;
  animation: bounce 1s infinite;
  pointer-events: none;
}

.loading-button:after {
  position: absolute;
  content: "✔";
  color: transparent;
  text-shadow: 0 0 0 white;
  display: block;
  top: 60px;
  left: 16px;
  transition: 0.3s;
  font-size: 18px;
  line-height: 1;
}
.loading-button.success span {
  display: none;
}
.loading-button.success:after {
  top: 8px;
  left: 9px;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    height: 28px;
    display: block;
  }
  40% {
    transform: translateY(-30px);
    height: 28px;
    display: block;
  }
  60% {
    transform: translateY(-15px);
    height: 28px;
    display: block;
  }
}
.approval-list.dropdown:hover > .dropdown-menu {
  display: block;
}
.custom_layout_cls .react-grid-item img {
  pointer-events: auto !important;
}
.audit_box {
  background-color: rgb(200, 133, 200) !important;
  background-color: #a156c4 !important;
  background-color: #f0e8f4 !important;
  border-radius: 5px;
}
.activity_box {
  padding: 20px 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.border-purple {
  border-color: #762b98 !important;
}