/* fixed header styling */
.fix-introducer-header .t-col-1 {
    width: 4%;
}

.fix-introducer-header .t-col-2 {
    width: 9%;
}
.fix-introducer-header .t-col-3 {
    width: 24%;
}

.fix-introducer-header .t-col-4 {
    width: 18.5%;
}

.fix-introducer-header .t-col-5 {
    width: 11%;
}

.fix-introducer-header .t-col-6 {
    width: 7.5%;
}

.fix-introducer-header .t-col-7 {
    width: 13%;
}

.fix-introducer-header .t-col-8 {
    width: 13%;
}

.hide-bottom-scrollbar{
    overflow-x: hidden;
}
.payment-count{
    background-color: #5c038517;
    width: 31%;
}
.payment-count:hover{
    background-color: #5c038533;
}

.bd-template .ck.ck-editor__main > .ck-editor__editable{
    height: 700px !important;
}