.filter-select-calender {
  top: 57px !important;
  z-index: 999 !important;
  right: 18%;
  width: auto;
  min-width: 18%;
}
#standard-bg-color {
  background-color: #c4b546 !important;
}
.text-content-default {
  background-color: #ae6aca;
  padding-left: 5px;
  padding-right: 5px;
}
.meetToggle .form-check-input:checked {
  /* background-color: #464775 !important; */
  /* border: 1px solid #464775 !important; */
  background-color: #330148 !important;
  border: 1px solid #330148 !important;
}
.dx-scheduler-date-time-indicator {
  background-color: #d51f3c !important;
  z-index: 0 !important;
}
.dx-scheduler-date-time-indicator::before {
  color: #d51f3c !important;
}
.dx-scheduler-time-panel-cell.dx-scheduler-time-panel-current-time-cell::before {
  background-color: #d51f3c !important;
}
.dx-scheduler-time-panel-current-time-cell {
  color: #d51f3c !important;
}
.dx-scheduler-work-space-odd-cells {
  padding-bottom: 2.5rem;
}
.dx-viewport-calender-2 .dx-scheduler-header {
  top: 11%;
}
.dx-state-hover {
  box-shadow: none !important;
}
.Attendees .z-2 {
  z-index: 2;
}
.dx-scheduler-appointment-popup {
  display: none;
}
.dx-overlay-wrapper {
  z-index: 777 !important;
}
.modal-dialog {
  z-index: 877 !important;
}
.dx-scheduler-date-table-container td,
.dx-scheduler-time-panel td {
  background-color: transparent !important;
}
.dx-scheduler-work-space-week .dx-scheduler-date-table-container td,
.dx-scheduler-work-space-week .dx-scheduler-time-panel td {
  height: 30px !important;
}
.dx-scheduler-work-space-month .dx-scheduler-appointment-content {
  height: 22px !important;
}
.dx-scheduler-work-space-month .dx-scheduler-date-table-container td,
.dx-scheduler-work-space-month .dx-scheduler-time-panel td {
  height: 102px !important;
}

.dx-scheduler-appointment {
  background-color: transparent !important;
  box-shadow: none;
}

.dx-tooltip-appointment-item-delete-button-container {
  display: none;
}
.bg-calender-content {
  background-color: #464775 !important;
  color: white !important;
}
.popup-mini {
  display: none;
}
.hover-container:hover .popup-mini {
  display: block !important;
  z-index: 9 !important;
}
.hover-container,
.fc-daygrid-day-frame {
  position: relative;
  z-index: 1 !important;
}

.popup-mini {
  position: absolute !important;
  top: 100% !important;
  left: 20%;
  padding: 1rem;
  background-color: white;
}
.popup-mini-title {
  text-align: left;
}
.meeting-container {
  position: absolute;
  left: 108%;
  top: -50% !important;
  background-color: white;
  transform: translate(0%, 15%);
}
.btn-meeting {
  background-color: #464775 !important;
  color: white !important;
}
.text-link {
  color: #6264a7 !important;
}
.btn-meeting:hover {
  color: white;
}
.meeting-popup {
  position: relative;
  border-radius: 10px;
}
.meeting-popup::before {
  content: "";
  position: absolute;
  top: 20%;
  left: -20px;
  transform: translateY(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

/* Modal */
.meetingModal {
  height: 90vh !important;
  z-index: 99999999 !important;
  --bs-modal-width: 70vw !important;
}
#modalToggle {
  position: absolute;
  background-color: #ffff;
  border-radius: 50%;
  right: -15px !important;
  top: -15px !important;
  z-index: 999 !important;
}
#modalToggle i {
  font-size: 15px !important;
}

/* ...................................................................................... */
.dx-scheduler-header .dx-toolbar .dx-toolbar-items-container {
  height: 45px !important;
  background-color: #6b2b86 !important;
}

.meet-content-type-3 {
  background-color: #26604b;
}
.meet-content-type-2 {
  background-color: #a0937d;
}
.meet-content-type-1 {
  background-color: #050c9c;
}
.meet-content-type-5 {
  background-color: #3abef9;
}
.white-image {
  filter: brightness(0) invert(1) grayscale(1);
}
.dx-scheduler-appointment-content {
  width: 10rem !important;
  /* height: 50px !important; */
  background-color: transparent;
  /* background-color: #337ab7; */
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.dx-scheduler-appointment-content {
  height: 25px !important;
  padding: 0 !important;
}

.dx-viewport-calender-2 .dx-scheduler-header {
  position: fixed;
  width: 100%;
  padding-left: 3% !important;
  padding-right: 3% !important;
  left: 0;
  top: 40px;
  height: 45px;
  border: none;
  z-index: 99;
  padding-top: 13px;
}
.dx-scheduler-header .dx-toolbar .dx-toolbar-item-content,
.dx-scheduler-header .dx-toolbar .dx-toolbar-menu-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.margin-top-calender-header {
  margin-top: 140px !important;
  /* margin-top: 20% !important; */
}
.dx-tooltip-appointment-item {
  z-index: 999999 !important;
}
.dx-scheduler-header .dx-toolbar {
  border: none;
}
.dx-scheduler-header-panel-empty-cell {
  height: fit-content;
}
.dx-viewport-calender-2 .dx-scheduler-header-panel-container {
  position: fixed;
  top: 90px;
  width: 94%;
  z-index: 98;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  /* background-color: blue; */
  background-color: white;
  height: 45px;
}
.copied-msg {
  position: absolute;
  right: 0%;
  top: 57%;
  background-color: #464775;
}
.copied-msg::before {
  content: "";
  position: absolute;
  bottom: -0.61rem;
  right: 17%;
  rotate: -91deg;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent #464775 transparent transparent;
}

@media screen and (min-width: 1600px) {
  .dx-viewport-calender-2 .dx-scheduler-header {
    padding-left: 2.8% !important;
    padding-right: 2.8% !important;
  }
}
