.sidebar-containerr {
  position: fixed !important;
  width: 20% !important;
  /* width: 5% !important; */
}

#credentialPage .nav-link {
  width: 20%;
}
.simplebar-scrollbar:before {
  /* background: #ff5733 !important; */
  position: absolute;
  left: 0 !important;
  margin-left: -10% !important;
}
.simplebar-track {
  left: -4% !important;
  right: unset !important;
}
.simplebar-mask {
  overflow: visible !important;
}
#cred .simplebar-scrollable-y {
  position: fixed !important;
}
#cred .credtabFix .simplebar-scrollable-y {
  top: 8rem !important;
}
.credtabFix {
  top: 8rem;
}
.credential-table {
  margin-left: 5%;
}

@media screen and (min-width: 1900px) {
  .credential-table {
    margin-left: 5%;
  }
}
