/* bidding account fix header */
.oppImg {
  position: fixed;
  width: 26%;
  top: 31%;
  transform: translateY(-20%);
  transition: all 5s ease;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.075) !important;
  padding: 0 1rem;
}
.fix-header-opportunity {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 60.4%;
  left: 35.4%;
  left: 35.4%;
}
.fix-bidding-header .t-col-1 {
  width: 7%;
}

.fix-bidding-header .t-col-2 {
  width: 17%;
}

.fix-bidding-header .t-col-3 {
  width: 17%;
}

.fix-bidding-header .t-col-4 {
  width: 17%;
}

.fix-bidding-header .t-col-5 {
  width: 26%;
}

.fix-bidding-header .t-col-6 {
  width: 16%;
}

/* data bank fix header */
.fix-databank-header .t-col-1 {
  width: 6%;
}

.fix-databank-header .t-col-2 {
  width: 19%;
}

.fix-databank-header .t-col-3 {
  width: 10%;
}

.fix-databank-header .t-col-4 {
  width: 11%;
}

.fix-databank-header .t-col-5 {
  width: 11%;
}

.fix-databank-header .t-col-6 {
  width: 10%;
}

.fix-databank-header .t-col-7 {
  width: 5%;
}

.fix-header-logs {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 68.3%;
  left: 27.5%;
}
.logImg {
  position: fixed;
  width: 23%;
  top: 40%;
  transform: translateY(-20%);
  transition: all 5s ease;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.075) !important;
  padding: 0 1rem;
}
.fix-viewdatabank-logs .t-col-1 {
  width: 5%;
}
.fix-viewdatabank-logs .t-col-2 {
  width: 12%;
}
.fix-viewdatabank-logs .t-head-2 {
  width: 25% !important;
}
.fix-viewdatabank-logs .t-col-3 {
  width: 8%;
}
.fix-viewdatabank-logs .t-col-4 {
  width: 8%;
}
.fix-viewdatabank-logs .t-col-5 {
  width: 8%;
}
.fix-viewdatabank-logs .t-col-6 {
  width: 9%;
}
.fix-viewdatabank-logs .t-col-7 {
  width: 7%;
}

/* importLogs Table */
.importLogs .t-col-1 {
  width: 5% !important;
}
.importLogs .t-col-2 {
  width: 9.8% !important;
}
.importLogs .t-col-3 {
  width: 9% !important;
}
.importLogs .t-col-4 {
  width: 8% !important;
}
.importLogs .t-col-5 {
  width: 8% !important;
}
.importLogs .t-col-6 {
  width: 8% !important;
}
.importLogs .t-col-7 {
  width: 8% !important;
}
.importLogs .t-col-8 {
  width: 5% !important;
}

/* ......................... */

/* view data bank fix header */
.fix-viewdatabank-header .t-col-1 {
  width: 5%;
}

.fix-viewdatabank-header .t-col-2 {
  width: 23.5%;
}
.fix-viewdatabank-header .t-col-3 {
  width: 13%;
}

.fix-viewdatabank-header .t-col-4 {
  width: 10%;
  text-align: center;
}

/* .fix-viewdatabank-header .t-col-4 {
  width: 21%;
} */

.fix-viewdatabank-header .t-col-5 {
  width: 23%;
  text-align: center;
}

.fix-viewdatabank-header .t-col-6 {
  width: 9.5%;
}

.fix-viewdatabank-header .t-col-7 {
  width: 8%;
}
.fix-viewdatabank-header .t-col-8 {
  width: 8%;
}

/* view cold header */
.fix-cold-header .t-col-1 {
  width: 4%;
}

.fix-cold-header .t-col-2 {
  width: 18%;
}

.fix-cold-header .t-col-3 {
  width: 13%;
  text-align: center;
}

.fix-cold-header .t-col-4 {
  width: 10%;
  text-align: center;
}

.fix-cold-header .t-col-5 {
  width: 16%;
  text-align: center;
}

.fix-cold-header .t-col-6 {
  width: 9%;
}

.fix-cold-header .t-col-7 {
  width: 19%;
}

.fix-cold-header .t-col-8 {
  width: 11%;
}

/* contact fix header */
.fix-contact-header .t-col-1 {
  width: 5%;
}

.fix-contact-header .t-col-2 {
  width: 18%;
}

.fix-contact-header .t-col-3 {
  width: 18%;
}

.fix-contact-header .t-col-4 {
  width: 15%;
}

.fix-contact-header .t-col-5 {
  width: 17%;
}

.fix-contact-header .t-col-6 {
  width: 15%;
}

.fix-contact-header .t-col-7 {
  width: 12%;
}

.overflowy-scroll {
  height: 300px;
  overflow-y: scroll;
}

.w-65 {
  width: 65% !important;
}

/* view Cold calling fix header */
.fix-calling-header .t-col-1 {
  width: 19%;
}

.fix-calling-header .t-col-2 {
  width: 13%;
  text-align: center;
}

.fix-calling-header .t-col-3 {
  width: 13%;
  text-align: center;
}

.fix-calling-header .t-col-4 {
  width: 13%;
  text-align: center;
}

.fix-calling-header .t-col-5 {
  width: 20%;
}

.fix-calling-header .t-col-6 {
  width: 12%;
  text-align: center;
}

.fix-calling-header .t-col-7 {
  width: 10%;
  text-align: center;
}

.tech-price {
  width: 11% !important;
  color: #000;
  font-size: 14px;
}

.tech-price1 {
  width: 20% !important;
  color: #000;
  font-size: 14px;
}

.tech-heading {
  /* border-bottom: 1px solid #5c0385; */
  padding: 10px 0px;
}

.tech-fields {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.bg-tech {
  height: 1px;
  background-color: #5c0385;
  margin: 10px 0;
}

.mr-3 {
  margin-right: 20px;
}

.w-55 {
  width: 55% !important;
}
@media screen and (min-width: 1600px) {
  .fix-header-opportunity {
    width: 61% !important;
    left: 35.1% !important;
    /* position: absolute !important;
    top: 0.8%; */
  }
  .opportunity-table .t-col-1 {
    width: 6% !important;
  }
  .opportunity-table .t-col-2 {
    width: 11% !important;
  }
  .opportunity-table .t-col-5 {
    width: 8% !important;
  }
  .opportunity-table .t-col-3 {
    width: 8% !important;
  }
  .opportunity-table .t-col-4 {
    width: 8% !important;
  }
  /* .fix-databank-header .t-head-1 {
    width: 6%;
  } */
  /* .fix-databank-header .t-head-2 {
    width: 14%;
  }
  .fix-databank-header .t-head-3 {
    width: 11%;
  }
  .fix-databank-header .t-head-4 {
    width: 10%;
  }
  .fix-databank-header .t-head-5 {
    width: 7%;
  } */
}
