.fix-header-currenopening {
  position: fixed;
  background-color: #981f77;
  color: white;
  z-index: 40;
  top: 19.2%;
  top: 120px;
  width: 60.4%;
  left: 35.4%;
  left: 35.4%;
}
.table-currenopening .t-col-1 {
  width: 4%;
}

.table-currenopening .t-col-2 {
  width: 30%;
}

.table-currenopening .t-col-3 {
  width: 8%;
}
.table-currenopening .t-col-4 {
  width: 10%;
}
.table-currenopening .t-col-5 {
  width: 10%;
}
.table-currenopening .t-col-6 {
  width: 10%;
}
.currentImg {
  position: fixed;
  width: 26%;
  top: 31%;
  transform: translateY(-20%);
  transition: all 5s ease;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.075) !important;
}
