.spinner-loader {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  top: 45%;
  left: 50%;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #5c0385;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 0px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 30px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 55px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.loader-line-3 {
  margin: 0 auto;
  width: 60px;
  height: 50px;
  text-align: center;
  position: relative;
  display: flex;
  top: 50%;
}
.loader-line-3 > div {
  height: 100%;
  width: 8px;
  display: inline-block;
  margin-left: 2px;
  animation: anm-LL-3-move 0.8s infinite ease-in-out;
}
.loader-line-3 .bar1 {
  background-color: #754fa0;
}
.loader-line-3 .bar2 {
  background-color: #09b7bf;
  animation-delay: -0.7s;
}
.loader-line-3 .bar3 {
  background-color: #90d36b;
  animation-delay: -0.6s;
}
.loader-line-3 .bar4 {
  background-color: #f2d40d;
  animation-delay: -0.5s;
}
.loader-line-3 .bar5 {
  background-color: #fcb12b;
  animation-delay: -0.4s;
}
.loader-line-3 .bar6 {
  background-color: #ed1b72;
  animation-delay: -0.3s;
}
@keyframes anm-LL-3-move {
  0%,
  40%,
  100% {
    transform: scaleY(0.05);
  }
  20% {
    transform: scaleY(1);
  }
}
