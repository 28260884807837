.fix-staff-header .t-col-1 {
  width: 8.4%;
  text-align: center;
}
.fix-staff-header .t-col-2 {
  width: 19%;
}
.fix-staff-header .t-col-3 {
  width: 19%;
}
.fix-staff-header .t-col-4 {
  width: 9%;
}
.fix-staff-header .t-col-5 {
  width: 17%;
}
.fix-staff-header .t-col-6 {
  /* width: 11%; */
  width: 10.5%;
}
.fix-staff-header .t-col-7 {
  /* width: 7%; */
  width: 6.5%;
}
.fix-staff-header .t-col-8 {
  width: 11%;
  text-align: center;
}

.password {
  position: relative;
}
.eye-icon {
  position: absolute;
  right: 8%;
  top: 62%;
  font-size: 11px;
}
.bd-custom-md-2 {
  max-width: 450px !important;
}
.bd-custom-md-3 {
  max-width: 350px !important;
}
.bd-source-tab {
  box-shadow: 2px 2px 5px #06000924;
  background-color: #f9f5fb !important;
  padding-top: 20px !important;
}

/* #editCategory-box .edit-category-img{
  width: 50%;
} */

#editCategory-box .edit-category-img img {
  width: 70%;
  height: auto;
  text-align: center;
}

#staff-permission {
  .fixed-header {
    top: 12.2%;
    width: 75.5%;
  }
}
/* //======================== Staff Hader ========= */
#staff {
  /* .fix-header .t-col-1 {
    width: 7.9% !important;
  } */
   
  .fix-header .t-col-3 {
    width: 19% !important;
  }
  .fix-header .t-col-3 {
    width: 19% !important;
  }
  .fix-header .t-col-4 {
    width: 10% !important;
  }
  .fix-header .t-col-5 {
    width: 17.5% !important;
  }
  .fix-header .t-col-6 {
    width: 11.04% !important;
  }
  .fix-header .t-col-7 {
    /* width: 8% !important; */
    width: 7.054% !important;
  }
  .fix-header .t-col-8 {
    width: 10% !important;
  }
}

.fix-staffs-header{
    position: fixed;
    background-color: #981f77;
    color: white;
    z-index: 40;
    /* top: 19.2%; */
    top: 120px;
    width: 92.2%;
    left: 4%;
}
.employee_code{
  width: 9.12% !important;
}
.fix-staffs-header .employee_code{
  width: 9.12% !important;
}

.employee_name{
  width: 19% !important;
}
.fix-staffs-header .employee_name{
  width: 20.5% !important;
}

.employee_designation{
  width: 19% !important;
}
.fix-staffs-header .employee_designation{
  /* width: 20.5% !important; */
  width: 20.45% !important;
}

.employee_phone{
  width: 9% !important;
}
.fix-staffs-header .employee_phone{
  width: 9.5% !important;
}

.employee_email{
  width: 17% !important;
}
.fix-staffs-header .employee_email{
  /* width: 18.03% !important; */
  width: 18.03% !important;
}

.employee_date_of_joining{
  width: 11.06% !important;
}
.fix-staffs-header .employee_date_of_joining{
  /* width: 11% !important; */
  width: 11.05% !important
}

.employee_status{
  width: 7.05% !important;
}
.fix-staffs-header .employee_status{
  /* width: 11% !important; */
  width: 7% !important
}

.employee_action{
  width: 11% !important;
}
.fix-staffs-header .employee_action{
  /* width: 11% !important; */
  width: 11% !important
}

@media screen and (min-width: 1200px) and (max-width : 1460px) {
  .fix-staff-header .t-col-1 {
    width: 12.3% !important;
    text-align: center;
  }
  .fix-staff-header .t-col-2 {
    width: 17% !important;
  }
  .fix-staff-header .t-col-3 {
    width: 17% !important;
  }
  .fix-staff-header .t-col-4 {
    width: 11.7% !important;
  }
  .fix-staff-header .t-col-5 {
    width: 17% !important;
  }
  .fix-staff-header .t-col-6 {
    width: 11% !important;
  }
  .fix-staff-header .t-col-7 {
    width: 7% !important;
  }
  .fix-staff-header .t-col-8 {
    /* width: 11% !important; */
    width: 7% !important;
    text-align: center;
  }
}

@media screen and (min-width : 1400px) {
  .form-switch-md{
    padding-left: 3.5rem !important;
  }
}

/* .empl-name-wrapper{
  width: 100%;
  max-width: max-content !important;
} */
/* .empl-name{
  width: 100%;
  max-width: max-content !important;
} */

