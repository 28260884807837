.navbar-header {
  max-width: 95% !important;
}

.Header {
  background-color: #330148 !important;
}
.dropdown-menu {
  right: -30%;
}
.zithas-logo {
  transform: translateX(-25px);
}

/* .setting-icon {
  transform: translateX(15px);
} */

.bd-max-size {
  max-width: 1285px !important;
}

.bd-max-size .modal-content {
  max-height: 580px;
  height: 580px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.bd-max-size .modal-content::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none;
}

.bd-listing-view label,
.bd-listing-view span {
  font-size: 16px;
}

.bd-fixed-header {
  position: fixed;
  width: 94%;
  background-color: white;
  z-index: 999;
  padding: 10px 1rem;
}

.bd-modal-body {
  margin-top: 50px;
  /* padding-top: 0px !important; */
}

.bd-fixed-sidetab {
  position: fixed !important;
  height: 79.5%;
  background-color: #f9f5fb !important;
}

.bd-right-side-tab {
  margin-left: 18%;
}

.bd-back-grey {
  background-color: #f9f9f9;
  padding: 20px;
}

.bd-back-white {
  background-color: white;
  padding: 20px;
}
.dropdown-head {
  width: 135px;
  overflow-x: hidden;
}
.dropdown-head-1 {
  width: 165px;
  overflow-x: hidden;
}
.dropdown-head-2 {
  width: 120px;
  overflow-x: hidden;
}
.dropdown-head-3 {
  width: 135px;
  overflow-x: hidden;
}
.dropdown-head-4 {
  width: 115px;
  overflow-x: hidden;
}
.dropdown-head-5 {
  width: 110px;
  overflow-x: hidden;
}
.dropdown-head-6 {
  width: 150px;
  overflow-x: hidden;
  margin-top: 15px;
}
#sales-dropdown {
  left: 10%;
  overflow: hidden;
}
